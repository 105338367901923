import models from "modules/models";
import moment from "moment";
import BaseRxApiService from "./baseRxApiService";
import firebase from "firebase/app"
import "firebase/firestore";

export default class ExpenseRxApiService extends BaseRxApiService {
  constructor() {
    super(models.EXPENSE);
  }

  monthlyExpenseStream(groupId) {
    return firebase
      .firestore()
      .collection(this.documentName)
      .where("groupId", "==", groupId)
      .where(
        "createdAt",
        ">=",
        new Date(moment().add(-30, "days").millisecond())
      )
      .orderBy("createdAt", "desc");
  }
}
