import { createSelector } from "reselect";

const root = (state) => state.user;

const selectShowFormModal = createSelector(
  root,
  (userState) => userState.showFormModal
);

const selectFormModalState = createSelector(root, (packageState) => ({
  showFormModal: packageState.showFormModal,
  editMode: packageState.initialFormValue ? true : false,
}));

const selectRows = createSelector(root, (userState) => ({
  rows: userState.rows,
  count: userState.count,
}));

const selectAppManagerIds = createSelector(root, (userState) => ({
  appManagerIds: userState.simplifiedRows.users.filter(u => u.roles.includes("appManager")).map(u => u.id)
}));

const selectSimplifiedRows = createSelector(root, (userState) => ({
  loadingSimplifiedUser: userState.loadingSimplifiedUser,
  simplifiedRows: userState.simplifiedRows,
}));

const isLoadingRows = createSelector(
  root,
  (userState) => userState.loadingUsers
);

const selectPageConfig = createSelector(root, (userState) => ({
  order: userState.order,
  orderBy: userState.orderBy,
  page: userState.page,
  rowsPerPage: userState.rowsPerPage,
  filter: userState.filter,
}));

export default {
  selectSimplifiedRows,
  selectShowFormModal,
  selectRows,
  selectAppManagerIds,
  isLoadingRows,
  selectPageConfig,
  selectFormModalState,
};
