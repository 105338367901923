import PermissionChecker from "modules/auth/permissionChecker";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import Layout from "view/layout/Layout";
import groupSelector from "modules/group/groupSelector";
import GroupStateService from "modules/group/groupStateService";
import { useSelector } from "react-redux";

function PrivateRoute({
  component: Component,
  currentUser,
  permissionRequired,
  serviceName,
  ...rest
}) {
  const { group } = useSelector(groupSelector.selectGroupConfig);
  const groupService = new GroupStateService(group);

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(currentUser);
        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/auth/signin",
                state: { from: props.location },
              }}
            />
          );
        }
        if (
          group &&
          groupService.serviceOptions.includes(serviceName) &&
          !groupService.isServiceEnabled(serviceName)
        ) {
          return <Redirect to="/settings" />;
        }

        if (permissionChecker.isEmptyPermissions) {
          return <Redirect to="/auth/empty-permissions" />;
        }

        if (permissionChecker.isMobileUserOnly) {
          return <Redirect to="/welcome" />;
        }

        if (!permissionChecker.match(permissionRequired)) {
          return <Redirect to="/403" />;
        }

        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}

export default PrivateRoute;
