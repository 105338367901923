import { map, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./bookingActions";
import { of } from "rxjs";
import BookingRxApiService from "apiServices/bookingRxApiService";
import Message from "view/shared/message";
import i18next from "i18next";
import ServiceRxApiService from "apiServices/serviceRxApiService";
import serviceAction from "../service/serviceActions";

const initialFetchEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      let { limit, order, orderBy, offset } = action.payload;
      return new ServiceRxApiService().get(null, null, "title", "asc").pipe(
        mergeMap((serviceResponse) =>
          new BookingRxApiService()
            .get(state$.value.booking.filter, limit, orderBy, order, offset)
            .pipe(
              mergeMap((bookingResponse) =>
                of(
                  serviceAction.fetchSuccess(
                    serviceResponse.data.rows,
                    serviceResponse.data.count
                  ),
                  actions.fetchSuccess(
                    bookingResponse.data.rows,
                    bookingResponse.data.count
                  )
                )
              )
            )
        ),

        catchError((err) => {
          console.error(err.response.data);
          Message.error(i18next.t("entities.booking.messages.fetcherror"));
          return of(actions.fetchError());
        })
      );
    })
  );

const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch(5, "createdAt", "desc", 0))
  );

const updateBookingEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_INIT),
    mergeMap((action) =>
      new BookingRxApiService().update(action.payload.data).pipe(
        mergeMap((response) => {
          return of(
            actions.updateSuccess(),
            actions.initializeFetch(
              state$.value.booking.rowsPerPage,
              state$.value.booking.orderBy,
              state$.value.booking.order,
              state$.value.booking.page,
              {
                startPoint: "at",
                doc: state$.value.booking.rows[0].id,
              },
              false
            )
          );
        }),
        catchError((error) => {
          return of(actions.updateError());
        })
      )
    )
  );

export default [initialFetchEpic, updateBookingEpic, setFilterEpic];
