export const createTrimmedDataModelForReadBy = (data) => {
  const trimmedData = {
    id: data.id,
    readBy: data.readBy,
    unreadBy: data.unreadBy,
  };
  return trimmedData;
};

export const createTrimmedModelForSendMessage = (chat) => {
  const trimmedData = {
    id: chat.id,
    participantIds: chat.participantIds,
  };
  return trimmedData;
};
