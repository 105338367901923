import { List } from "@material-ui/core";
import React, { Fragment } from "react";
import userSelector from "modules/user/userSelector";
import propertySelector from "modules/property/propertySelector";
import { useSelector } from "react-redux";
import ChatService from "modules/chat/chatService";
import { chatType } from "common/enums";
import ChatItem from "./chatItem";

export default function ChatInbox(props) {
  const { chats, chatItemSelected, currentUser } = props;

  const { simplifiedRows, loadingSimplifiedUser } = useSelector(
    userSelector.selectSimplifiedRows
  );

  const { unfilteredRows: properties, loadingUnfilteredRows } = useSelector(
    propertySelector.selectRows
  );

  if (loadingSimplifiedUser || loadingUnfilteredRows) {
    return <Fragment></Fragment>;
  }

  return (
    <List>
      {chats.map((chat) => {
        const isUserChat = chat.chatType === chatType.USER;
        const populatedChat = ChatService.populateChatObject(
          chat,
          currentUser,
          properties,
          simplifiedRows
        );
        const read = chat.readBy.some((id) => id === currentUser.id);
        return (
          <ChatItem
            key={chat.id}
            chatItemSelected={chatItemSelected}
            populatedChat={populatedChat}
            chat={chat}
            read={read}
            isUserChat={isUserChat}
          ></ChatItem>
        );
      })}
    </List>
  );
}
