// @ts-nocheck
import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import authSelectors from "modules/auth/authSelectors.js";
import { useSelector, useDispatch } from "react-redux";
import PermissionChecker from "modules/auth/permissionChecker";
import actions from "modules/layout/layoutActions";
import layoutSelectors from "modules/layout/layoutSelectors";
import routes from "view/routes";
import clsx from "clsx";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import groupSelector from "modules/group/groupSelector";
import GroupStateService from "modules/group/groupStateService";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export default function Menu(props) { 
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);
  const { group, subscription } = useSelector(groupSelector.selectGroupConfig);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const groupService = new GroupStateService(group);

  const selectedKeys = () => {
    const url = props.url;
    const match = routes.privateRoutes.find((option) => {
      if (option.menu.exact) {
        return url === option.path;
      }

      return url === option.path || url.startsWith(option.path + "/");
    });

    if (match) {
      return [match.path];
    }

    return null;
  };

  const hideMenu = () => {
    dispatch(actions.doHideMenu());
  };

  const isPermitted = (permission) => {
    const permissionChecker = new PermissionChecker(currentUser);
    return permissionChecker.match(permission);
  };

  const isEnabled = (privateRoute) => {
    if (privateRoute.alwaysEnabled) {
      return true;
    }
    return groupService.isServiceEnabled(privateRoute.model);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{
        flexGrow: 1,
      }}
    >
      <Link
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
        {...props}
      />
    </div>
  ));

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: menuVisible,
        [classes.drawerClose]: !menuVisible,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: menuVisible,
          [classes.drawerClose]: !menuVisible,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={hideMenu}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {routes.privateRoutes
          .filter((privateRoute) => !!privateRoute.menu)
          .filter(
            (privateRoute) =>
              subscription.features.some((feature) => feature === privateRoute.model) ||
              privateRoute.permissionRequired === null
          )
          .filter((privateRoutes) =>
            isPermitted(privateRoutes.permissionRequired)
          )
          .filter((privateRoutes) => isEnabled(privateRoutes))
          .map((privateRoute) => (
            <CustomRouterLink key={privateRoute.path} to={privateRoute.path}>
              <ListItem button>
                {!menuVisible ? (
                  <Tooltip title={privateRoute.label()} placement="right-start">
                    <ListItemIcon
                      className={clsx({
                        [classes.active]: selectedKeys().includes(
                          privateRoute.path
                        ),
                      })}
                      key={privateRoute.label()}
                    >
                      {privateRoute.icon}
                    </ListItemIcon>
                  </Tooltip>
                ) : (
                  <ListItemIcon
                    className={clsx({
                      [classes.active]: selectedKeys().includes(
                        privateRoute.path
                      ),
                    })}
                    key={privateRoute.label()}
                  >
                    {privateRoute.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  className={clsx({
                    [classes.active]: selectedKeys().includes(
                      privateRoute.path
                    ),
                  })}
                  primary={privateRoute.label()}
                />
              </ListItem>
            </CustomRouterLink>
          ))}
      </List>
    </Drawer>
  );
}
