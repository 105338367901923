import authAxiosObs from "common/axios/authAxiosObservable";
import models from "modules/models";


export default class KeyRequestLogRxApiService {
  constructor() {
    this.documentName = models.KEYREQUESTLOG;
  }
  get(filter, limit = null, orderBy = null, order = null, offset = null) {
    const params = { filter, limit, orderBy, order, offset };
    return authAxiosObs.get(`/${this.documentName}`, { params });
  }

  getLogsByPropertyId(propertyId) {
    return authAxiosObs.put(`/${this.documentName}/property/${propertyId}`);
  }

  getLogsByRequestId(requestId) {
    return authAxiosObs.put(`/${this.documentName}/request/${requestId}`);
  }


}
