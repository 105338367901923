import firebase from "firebase/app"
import "firebase/firestore";
import authApiService from "apiServices/authApiService";
import BaseApiService from "./baseApiService";
import authAxios from "common/axios/authAxios";
import models from "modules/models";
import * as _ from "lodash";

export default class ChatApiService extends BaseApiService {
  constructor() {
    super(models.CHAT);
  }

  async allChatStream() {
    var currentUser = await new authApiService().fetchMe();
    return firebase
      .firestore()
      .collection(models.CHAT)
      .where("groupId", "==", currentUser.groupId)
      .where("participantIds", "array-contains", currentUser.id)
      .orderBy("updatedAt", "desc");
  }

  async chatStream(id) {
    return firebase.firestore().collection(models.CHAT).doc(id);
  }

  async createOrRetrieveChat(chat) {
    var currentUser = await new authApiService().fetchMe();
    var chatSnapshots = await firebase
      .firestore()
      .collection(models.CHAT)
      .where("groupId", "==", currentUser.groupId)
      .where("chatId", "==", chat.chatId)
      .get();
    if (chatSnapshots.empty) {
      const response = await this.create(chat);
      return response.data;
    }
    let response = chatSnapshots.docs[0].data();
    if (!this._noChangeInParticipants(chat, response)) {
      response.participantIds = chat.participantIds;
      await this.update(response);
    }
    return response;
  }

  async sendMessage(chat, text) {
    return await authAxios.put(`/${models.CHAT}/sendMessage`, {
      chat,
      message: text,
    });
  }
  async updateReadBy(chat) {
    return await authAxios.put(`/${models.CHAT}/updatereadby`, {
      model: chat,
    });
  }

  _noChangeInParticipants(generatedChat, serverChat) {
    return _.isEqual(generatedChat.participantIds.sort(), serverChat.participantIds.sort());
  }
}
