import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import SettingsService from "modules/settings/settingsService";
import configure from "lang/i18nConfig";
import i18next from "i18next";

(async function () {
  const settings = await SettingsService.initialFetch();
  sessionStorage.setItem("settings", JSON.stringify(settings));
  await configure();

  document.title = i18next.t("app.title");
  ReactDOM.render(<App settings={settings} />, document.getElementById("root"));

  serviceWorker.unregister();
  // registerServiceWorker();
})();
