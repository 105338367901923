import { map, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./packageActions";
import { of } from "rxjs";
import PackageRxApiService from "apiServices/packageRxApiService";
import Message from "view/shared/message";
import i18next from "i18next";

const initialFetchEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      let { limit, order, orderBy, offset } = action.payload;
      return new PackageRxApiService()
        .get(state$.value.packages.filter, limit, orderBy, order, offset)
        .pipe(
          map((response) =>
            actions.fetchSuccess(response.data.rows, response.data.count)
          ),
          catchError((err) => {
            console.error(err.response.data);
            Message.error(i18next.t("entities.package.messages.fetcherror"));
            return of(actions.fetchError());
          })
        );
    })
  );

const createPackageEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CREATE_INIT),
    mergeMap((action) =>
      new PackageRxApiService().create(action.payload.data).pipe(
        mergeMap((response) => {
          Message.success(i18next.t("entities.package.messages.createSuccess"));
          return of(
            actions.createSuccess(),
            actions.initializeFetch(
              state$.value.packages.rowsPerPage,
              "createdAt",
              "desc",
              0,
              null,
              false
            )
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.package.messages.createError"));
          console.error(error);
          return of(actions.createError());
        })
      )
    )
  );

const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch(5, "createdAt", "desc", 0))
  );

const updatePackageEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_INIT),
    mergeMap((action) =>
      new PackageRxApiService().update(action.payload.data).pipe(
        mergeMap((response) => {
          return of(
            actions.updateSuccess(),
            actions.initializeFetch(
              state$.value.packages.rowsPerPage,
              state$.value.packages.orderBy,
              state$.value.packages.order,
              state$.value.packages.page,
              {
                startPoint: "at",
                doc: state$.value.packages.rows[0].id,
              },
              false
            )
          );
        }),
        catchError((error) => {
          return of(actions.updateError());
        })
      )
    )
  );

export default [
  initialFetchEpic,
  createPackageEpic,
  updatePackageEpic,
  setFilterEpic,
];
