import authAxiosObs from "common/axios/authAxiosObservable";
export default class SubscriptionApiService {

  getSubscriptionById(subscriptionId) {
    return authAxiosObs.get(`/subscription/${subscriptionId}`);
  }
  getSubscriptionByEmail(email) {
    return authAxiosObs.get(`/subscription/email/${email}`);
  }
}
