export const propertyTypeAsString = (index) => {
  switch (index) {
    case 0:
      return "Flat";
    case 1:
      return "House";
    default:
      break;
  }
};

export const propertyToString = (property) => {
  if (!property) {
    return "Unknown Property";
  }
  return `${property.number} ${property.name} (${propertyTypeAsString(
    property.type
  )})`;
}
