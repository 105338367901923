import { actionTypes } from "./groupActions";

const initialState = {
  subscription: null,
  group: null,
  loadingGroupInformation: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INIT:
    case actionTypes.UPDATE_INIT:
      return { ...state, loadingGroupInformation: true };
    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        group: action.payload.group,
      };
    case actionTypes.FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loadingGroupInformation: false,
        subscription: action.payload,
      };
    case actionTypes.FETCH_ERROR:
    case actionTypes.FETCH_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loadingGroupInformation: false,
      };

    default:
      return state;
  }
};
