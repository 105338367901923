import authAxiosObs from "common/axios/authAxiosObservable";
import models from "modules/models";

export default class BaseApiService {
  constructor() {
    this.documentName = models.KEYREQUEST;
  }
  create(data) {
    const body = {
      data,
    };
    return authAxiosObs.post(`/${this.documentName}`, body);
  }

  createCollector(data) {
    const body = {
      data,
    };
    return authAxiosObs.post(`/${this.documentName}/collector`, body);
  }

  get(filter, limit = null, orderBy = null, order = null, offset = null) {
    const params = { filter, limit, orderBy, order, offset };
    return authAxiosObs.get(`/${this.documentName}`, { params });
  }

  getCollectorsByPropertyId(id) {
    return authAxiosObs.get(`/${this.documentName}/collectors/${id}`);
  }

  getById(id) {
    return authAxiosObs.get(`/${this.documentName}/${id}`);
  }

  updateRequest(request) {

    return authAxiosObs.put(`/${this.documentName}`, request)
  }

  cancelRequest(requestId) {
    return authAxiosObs.put(`/${this.documentName}/cancel/${requestId}`);
  }

  confirmCollection(requestId, collectorId, note) {
    const data = {
      note
    }
    return authAxiosObs.put(`/${this.documentName}/${requestId}/collected/${collectorId}`, data);
  }

  confirmReturn(requestId, collectorId, note) {
    const data = {
      note
    }
    return authAxiosObs.put(`/${this.documentName}/${requestId}/returned/${collectorId}`, data);
  }

  confirmMissingReturn(requestId, collectorId, note) {
    const data = {
      note
    }
    return authAxiosObs.put(`/${this.documentName}/${requestId}/returnedmissing/${collectorId}`, data);
  }

  confirmNotReturned(requestId) {
    return authAxiosObs.put(`/${this.documentName}/${requestId}/notReturned`);
  }

}
