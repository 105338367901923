import { Button, ButtonBase, Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import React from "react"
import authAction from "modules/auth/authActions"
import { useDispatch } from "react-redux"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
    },
    paper: {
        padding: theme.spacing(0),
        minWidth: 600,
        height: 300
    },
    image: {
        width: "100%",
        height: "100%",

    },
    img: {
        WebkitBorderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
        borderTopLeftRadius: "5px",
        WebkitBorderBottomLeftRadius: "5px",
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },

    leftSection: {
        height: 300
    },

    rightSection: {
        padding: theme.spacing(1)
    }



}))

export default function NotSubscribed(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    return <div className={classes.root}>
        <Paper className={classes.paper}>
            <Grid container direction="row">
                <Grid item xs={6} className={classes.leftSection}>
                    <ButtonBase className={classes.image}>
                        <img className={classes.img} alt="complex" src="images/minilogo.png" />
                    </ButtonBase>
                </Grid>
                <Grid item xs={6} sm container className={classes.rightSection}>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1">
                                Invalid Subscription
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                It appears you do not have a valid subscription
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                To renew your subscription visit Atalo.co
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Group Name : {props.group}
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent="space-between">
                            <Button variant="outlined" onClick={() => window.open("http://atalo.co")}>
                                Renew
                            </Button>
                            <Button variant="outlined" onClick={() => dispatch(authAction.authSignout())}>
                                SIGNOUT
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </div>
}