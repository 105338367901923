import BaseRxApiService from "./baseRxApiService";
import firebase from "firebase/app"
import "firebase/firestore";
import models from "modules/models";
import moment from "moment";
import authAxiosObs from "common/axios/authAxiosObservable";

export default class IssueRxApiService extends BaseRxApiService {
  constructor() {
    super(models.ISSUE);
  }
  issueStream(id) {
    return firebase.firestore().collection(this.documentName).doc(id);
  }
  openIssueStream(groupId) {
    return firebase
      .firestore()
      .collection(this.documentName)
      .where("groupId", "==", groupId)
      .where("resolved", "==", false);
  }

  monthlyIssueStream(groupId) {
    const earliestDate = new Date(moment().add(-30, "days").toDate());

    return firebase
      .firestore()
      .collection(this.documentName)
      .where("groupId", "==", groupId)
      .where(
        "createdAt",
        ">=",
        earliestDate
      )
      .orderBy("createdAt", "desc");
  }

  updateReadBy(issue) {
    return authAxiosObs.put(`/${models.ISSUE}/updatereadby`, {
      model: issue,
    });
  }
}
