const env = "prod"
const firebaseConfig = {
  apiKey: "AIzaSyDJpKWb91NancPt1zsIXBL5Mde1h0N67Is",
  authDomain: "conciergeapp-prod.firebaseapp.com",
  databaseURL: "https://conciergeapp-prod.firebaseio.com",
  projectId: "conciergeapp-prod",
  storageBucket: "conciergeapp-prod.appspot.com",
  messagingSenderId: "630272984019",
  appId: "1:630272984019:web:b84af94cf3abdd35c9e526",
  measurementId: "G-159YPCJWRP"
};

const backendUrl = `https://europe-west2-${firebaseConfig.projectId}.cloudfunctions.net/backend/api`;
const preauthToken = "c11e2e5b-4115-436c-bb03-6b2bda49c455";
const VAPID = "BH8dsX7CU-Z0ISs_RcYVg-a6KqAI2DpfblTpQCHJmAzKW9xlz844UTIJZgaQQcrfgOjScPm6Dfu9gupNEDMaDMI"



export default {
  env,
  firebaseConfig,
  backendUrl,
  preauthToken,
  VAPID,
};
