import config from "../../config";
import firebase from "firebase/app"
import "firebase/auth";
import "firebase/storage";
import { QFirebase } from "@quarkmodule/firebasewrapper";

export default (store) => {
  firebase.initializeApp(config.firebaseConfig);
  QFirebase.setFireBase(firebase);
};
