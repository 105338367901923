const prefix = "USER";

export const actionTypes = {
  FETCH_INIT: `${prefix}_FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  CREATE_INIT: `${prefix}_CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,
  UPDATE_INIT: `${prefix}_UPDATE_INIT`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
  UPDATE_ALLOCATION: `${prefix}_UPDATE_ALLOCATION`,
  LOAD_SIMPLIFIED_ROWS_INIT: `${prefix}_LOAD_SIMPLIFIED_ROWS_INIT`,
  LOAD_SIMPLIFIED_ROWS_SUCCESS: `${prefix}_LOAD_SIMPLIFIED_ROWS_SUCCESS`,
  LOAD_SIMPLIFIED_ROWS_ERROR: `${prefix}_LOAD_SIMPLIFIED_ROWS_ERROR`,
  SHOW_FORM: `${prefix}_SHOW_FORM`,
  CLOSE_FORM: `${prefix}_CLOSE_FORM`,
  SET_FILTER: `${prefix}_SET_FILTER`,
  SET_PAGE: `${prefix}_SET_PAGE`,
  SET_ORDER: `${prefix}_SET_ORDER`,
  SET_ORDERBY: `${prefix}_SET_ORDERBY`,
  SET_ROWS_PER_PAGE: `${prefix}_SET_ROWS_PER_PAGE`,
  DELETE_INIT: `${prefix}_DELETE_INIT`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,
  CLEAR_CACHE: `${prefix}_CLEAR_CACHE`,
};

const actions = {
  openUserForm: (formValue = null) => ({
    type: actionTypes.SHOW_FORM,
    payload: {
      formValue,
    },
  }),
  closeUserForm: () => ({
    type: actionTypes.CLOSE_FORM,
  }),
  createUser: (data) => ({
    type: actionTypes.CREATE_INIT,
    payload: { data },
  }),

  createSuccess: () => ({
    type: actionTypes.CREATE_SUCCESS,
  }),

  createError: () => ({
    type: actionTypes.CREATE_ERROR,
  }),

  updateAllocation: (modifiedTenants) => ({
    type: actionTypes.UPDATE_ALLOCATION,
    payload: {
      modifiedTenants,
    },
  }),

  updateUser: (data, uploadImage) => ({
    type: actionTypes.UPDATE_INIT,
    payload: { data, uploadImage },
  }),
  updateSuccess: () => ({
    type: actionTypes.UPDATE_SUCCESS,
  }),
  updateError: () => ({
    type: actionTypes.UPDATE_ERROR,
  }),

  initializeFetch: (
    limit,
    orderBy,
    order,
    page,
    offset = null,
    loadingScreen = true
  ) => ({
    type: actionTypes.FETCH_INIT,
    payload: {
      page,
      limit,
      offset,
      orderBy,
      order,
      loadingScreen,
    },
  }),

  fetchSuccess: (rows, count) => ({
    type: actionTypes.FETCH_SUCCESS,
    payload: {
      rows,
      count,
    },
  }),

  fetchError: () => ({
    type: actionTypes.FETCH_ERROR,
  }),

  loadSimplifiedRows: () => ({
    type: actionTypes.LOAD_SIMPLIFIED_ROWS_INIT,
  }),

  loadSimplifiedRowsSuccess: (rows) => ({
    type: actionTypes.LOAD_SIMPLIFIED_ROWS_SUCCESS,
    payload: {
      rows,
    },
  }),

  loadSimplifiedRowsError: () => ({
    type: actionTypes.LOAD_SIMPLIFIED_ROWS_ERROR,
  }),

  deleteUsers: (userIds) => ({
    type: actionTypes.DELETE_INIT,
    payload: {
      userIds,
    },
  }),

  deleteUsersSuccess: () => ({
    type: actionTypes.DELETE_SUCCESS,
  }),
  deleteUsersError: () => ({
    type: actionTypes.DELETE_ERROR,
  }),

  setFilter: (filter) => ({
    type: actionTypes.SET_FILTER,
    payload: {
      filter,
    },
  }),
  setOrder: (order) => ({
    type: actionTypes.SET_ORDER,
    payload: {
      order,
    },
  }),
  setOrderBy: (orderBy) => ({
    type: actionTypes.SET_ORDERBY,
    payload: {
      orderBy,
    },
  }),
  setPage: (page) => ({
    type: actionTypes.SET_PAGE,
    payload: {
      page,
    },
  }),
  setRowsPerPage: (rowsPerPage) => ({
    type: actionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }),

  clearCache: () => ({
    type: actionTypes.CLEAR_CACHE,
  }),
};

export default actions;
