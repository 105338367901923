import { createSelector } from "reselect";

const root = (state) => state.group;

const selectGroupConfig = createSelector(root, (groupState) => ({
  subscription: groupState.subscription,
  group: groupState.group,
  loadingGroupInformation: groupState.loadingGroupInformation,
}));

export default {
  selectGroupConfig,
};
