import { propertyChatTypes } from "common/enums";

export const propertyChatTypeAsString = (chatType) => {
    switch (chatType) {
        case propertyChatTypes.ALL:
            return "All";

        case propertyChatTypes.BROADCAST:
            return "Broadcast";

        case propertyChatTypes.LANDLORD:
            return "Landlord";

        case propertyChatTypes.TENANT:
            return "Tenants";


        default:
            break;
    }
}