import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { languages } from "lang";

const loadResourcesFromJson = async () => {
  const resources = {
    emptyObject: {},
  };

  const getJsonFromUrl = async (url) => {
    const response = await fetch(url);
    return await response.json();
  };

  const keys = Object.keys(languages);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const lang = languages[key];
    let translation = {
      translation: await getJsonFromUrl(lang.jsonUrl),
    };
    resources[key] = translation;
  }
  return resources;
};

const configure = async () => {
  const resources = await loadResourcesFromJson();

  await i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources,
      lng: "en",
      debug: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
};
export default configure;
