import models from "modules/models";
import BaseRxApiService from "./baseRxApiService";
import firebase from "firebase/app"
import "firebase/firestore";
import { bookingStatus } from "common/enums";

export default class BookingRxApiService extends BaseRxApiService {
  constructor() {
    super(models.BOOKING);
  }
  activeBookingStream(groupId) {
    return firebase
      .firestore()
      .collection(this.documentName)
      .where("groupId", "==", groupId)
      .where("status", "in", [
        bookingStatus.PENDING,
        bookingStatus.BOOKED,
        bookingStatus.READY,
      ]);
  }
}
