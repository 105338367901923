import { map, mergeMap, catchError, delay } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./postActions";
import { concat, of } from "rxjs";
import PostRxApiService from "apiServices/postRxApiService";
import Message from "view/shared/message";
import i18next from "i18next";

const initialFetchEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      let { limit, order, orderBy, offset } = action.payload;
      return new PostRxApiService()
        .get(state$.value.post.filter, limit, orderBy, order, offset)
        .pipe(
          map((response) =>
            actions.fetchSuccess(response.data.rows, response.data.count)
          ),
          catchError((err) => {
            console.error(err.response.data);
            Message.error(i18next.t("entities.post.messages.fetchError"));
            return of(actions.fetchError());
          })
        );
    })
  );

const createPostEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CREATE_IMAGED_POST_INIT),
    delay(20000), // Allow time for processing of images
    mergeMap((action) =>
      new PostRxApiService().create(action.payload.data).pipe(
        mergeMap((response) => {
          Message.success(i18next.t("entities.post.messages.createSuccess"));
          return of(
            actions.createImagedSuccess(),
            actions.initializeFetch(
              state$.value.post.rowsPerPage,
              "createdAt",
              "desc",
              0,
              null,
              false
            )
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.post.messages.createError"));
          console.error(error);
          return of(actions.createError());
        })
      )
    )
  );

const createTextPostEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CREATE_INIT),
    mergeMap((action) =>
      new PostRxApiService().create(action.payload.data).pipe(
        mergeMap((response) => {
          Message.success(i18next.t("entities.post.messages.createSuccess"));
          return of(
            actions.createSuccess(),
            actions.initializeFetch(
              state$.value.post.rowsPerPage,
              "createdAt",
              "desc",
              0,
              null,
              false
            )
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.post.messages.createError"));
          console.error(error);
          return of(actions.createError());
        })
      )
    )
  );

const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch(5, "createdAt", "desc", 0))
  );

const updatePostEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_INIT),
    mergeMap((action) =>
      new PostRxApiService().update(action.payload.data).pipe(
        mergeMap((response) => {
          return of(
            actions.updateSuccess(),
            actions.initializeFetch(
              state$.value.post.rowsPerPage,
              state$.value.post.orderBy,
              state$.value.post.order,
              state$.value.post.page,
              {
                startPoint: "at",
                doc: state$.value.post.rows[0].id,
              },
              false
            )
          );
        }),
        catchError((error) => {
          return of(actions.updateError());
        })
      )
    )
  );

const deletePostEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.DELETE_INIT),
    mergeMap((action) =>
      new PostRxApiService().deleteList(action.payload.postIds).pipe(
        mergeMap(() => {
          const { rowsPerPage, page, order, orderBy } = state$.value.post;
          Message.success(
            i18next.t("entities.post.messages.postDeleteSuccess")
          );
          return concat(
            of(actions.deletePostSuccess()),
            of(actions.initializeFetch(rowsPerPage, orderBy, order, page))
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.post.messages.postDeleteError"));
          return of(actions.deletePostError());
        })
      )
    )
  );

export default [
  initialFetchEpic,
  createPostEpic,
  updatePostEpic,
  setFilterEpic,
  deletePostEpic,
  createTextPostEpic,
];
