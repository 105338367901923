import { ConnectedRouter } from "connected-react-router";
import { configureStore, getHistory } from "modules/store";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import RoutesComponent from "view/shared/routes/RoutesComponent";
import "typeface-roboto";
import {
  CssBaseline,
  MuiThemeProvider,
} from "@material-ui/core";
import Message from "view/shared/message";
import settingsThemeConverter from "modules/settings/settingsThemeConverter";
import { fx } from "money";
import { createTheme } from '@material-ui/core/styles'

const store = configureStore();

export default function AppCore(props) {
  useEffect(() => {
    Message.registerNotistakEnqueueSnackbar(props.enqueueSnackbar);
  }, []);

  fx.rates = {
    GBP: 1,
    USD: 1.40,
    EUR: 1.16,
  };
  fx.base = "GBP";
  const { settings } = props;
  document.addEventListener("touchstart", () => { }, { passive: true });
  const theme = createTheme({
    palette: {
      primary: {
        main: settings.primaryColor,
      },
      secondary: {
        main: settings.secondaryColor,
      },
      info: {
        main: settings.infoColor,
      },
      success: {
        main: settings.successColor,
      },
      error: {
        main: settings.errorColor,
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <Provider
          // @ts-ignore
          store={store}
        >
          <ConnectedRouter history={getHistory()}>
            <RoutesComponent />
          </ConnectedRouter>
        </Provider>
      </React.Fragment>
    </MuiThemeProvider>
  );
}
