import { map, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./keyRequestActions";
import { of } from "rxjs";
import KeyRequestRxApiService from "apiServices/keyRequestRxApiService";
import Message from "view/shared/message";
import i18next from "i18next";

const initialFetchEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      let { limit, order, orderBy, offset } = action.payload;
      return new KeyRequestRxApiService()
        .get(state$.value.keyrequest.filter, limit, orderBy, order, offset)
        .pipe(
          map((response) =>
            actions.fetchSuccess(response.data.rows, response.data.count)
          ),
          catchError((err) => {
            console.error(err.response.data);
            Message.error(i18next.t("entities.keyRequest.messages.fetcherror"));
            return of(actions.fetchError());
          })
        );
    })
  );

const createKeyRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CREATE_INIT),
    mergeMap((action) =>
      new KeyRequestRxApiService().create(action.payload.data).pipe(
        mergeMap((response) => {
          Message.success(i18next.t("entities.keyRequest.messages.createSuccess"));
          return of(
            actions.createSuccess(),
            actions.initializeFetch(
              state$.value.keyrequest.rowsPerPage,
              "createdAt",
              "desc",
              0,
              null,
              false
            )
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.keyRequest.messages.createError"));
          return of(actions.createError());
        })
      )
    )
  );

const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch(5, "createdAt", "desc", 0))
  );

const fetchAfterUpdate = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_SUCCESS,
      actionTypes.CANCEL_REQUEST_SUCCESS,
      actionTypes.CONFIRM_COLLECTION_SUCCESS,
      actionTypes.CONFIRM_RETURN_SUCCESS,
      actionTypes.CONFIRM_NOT_RETURNED_SUCCESS,
      actionTypes.CONFIRM_MISSING_RETURN_SUCCESS),
    map((r) => actions.initializeFetch(
      state$.value.keyrequest.rowsPerPage,
      state$.value.keyrequest.orderBy,
      state$.value.keyrequest.order,
      state$.value.keyrequest.page,
      {
        startPoint: "at",
        doc: state$.value.keyrequest.rows[0].id,
      },
      false
    ))
  );

const updateRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_INIT),
    mergeMap((action) =>
      new KeyRequestRxApiService().updateRequest(action.payload.data).pipe(
        map((response) => actions.updateSuccess()),
        catchError((error) => {
          Message.error(i18next.t("entities.keyRequest.messages.updateError"));
          return of(actions.updateError());
        })
      )
    ));

const cancelRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CANCEL_REQUEST_INIT),
    mergeMap((action) =>
      new KeyRequestRxApiService().cancelRequest(action.payload.id).pipe(
        map((response) => actions.cancelRequestSuccess()),
        catchError((error) => {
          Message.error(i18next.t("entities.keyRequest.messages.updateError"));
          return of(actions.updateError());
        })
      )
    ));

const confirmCollectionEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CONFIRM_COLLECTION_INIT),
    mergeMap((action) =>
      new KeyRequestRxApiService()
        .confirmCollection(action.payload.requestId, action.payload.collectorId, action.payload.note).pipe(
          map((response) => actions.confirmCollectionSuccess()),
          catchError((error) => {
            Message.error(i18next.t("entities.keyRequest.messages.updateError"));
            return of(actions.updateError());
          })
        )
    ));

const confirmReturnEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CONFIRM_RETURN_INIT),
    mergeMap((action) =>
      new KeyRequestRxApiService()
        .confirmReturn(action.payload.requestId, action.payload.collectorId, action.payload.note).pipe(
          map((response) => actions.confirmReturnSuccess()),
          catchError((error) => {
            Message.error(i18next.t("entities.keyRequest.messages.updateError"));
            return of(actions.updateError());
          })
        )
    ));
const confirmNotReturnedEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CONFIRM_NOT_RETURNED_INIT),
    mergeMap((action) =>
      new KeyRequestRxApiService().confirmNotReturned(action.payload.requestId).pipe(
        map((response) => actions.confirmNotReturnedSuccess()),
        catchError((error) => {
          Message.error(i18next.t("entities.keyRequest.messages.updateError"));
          return of(actions.updateError());
        })
      )
    ));

const confirmMissingReturnEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CONFIRM_MISSING_RETURN_INIT),
    mergeMap((action) =>
      new KeyRequestRxApiService()
        .confirmMissingReturn(action.payload.requestId, action.payload.collectorId, action.payload.note).pipe(
          map((response) => actions.confirmMissingReturnSuccess()),
          catchError((error) => {
            Message.error(i18next.t("entities.keyRequest.messages.updateError"));
            return of(actions.updateError());
          })
        )
    ));

export default [
  initialFetchEpic,
  createKeyRequestEpic,
  updateRequestEpic,
  setFilterEpic,
  fetchAfterUpdate,
  cancelRequestEpic,
  confirmCollectionEpic,
  confirmMissingReturnEpic,
  confirmNotReturnedEpic,
  confirmReturnEpic
];
