const prefix = "POST";

export const actionTypes = {
  FETCH_INIT: `${prefix}_FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  CREATE_INIT: `${prefix}_CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,
  CREATE_IMAGED_POST_INIT: `${prefix}_CREATE_IMAGED_POST_INIT`,
  CREATE_IMAGED_POST_SUCCESS: `${prefix}_CREATE_IMAGED_POST_SUCCESS`,
  CREATE_IMAGED_POST_ERROR: `${prefix}_CREATE_IMAGED_POST_ERROR`,
  UPDATE_INIT: `${prefix}_UPDATE_INIT`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
  SHOW_FORM: `${prefix}_SHOW_FORM`,
  CLOSE_FORM: `${prefix}_CLOSE_FORM`,
  SHOW_IMAGE_FORM: `${prefix}_SHOW_IMAGE_FORM`,
  CLOSE_IMAGE_FORM: `${prefix}_CLOSE_IMAGE_FORM`,
  SHOW_TEXT_DIALOG: `${prefix}_SHOW_TEXT_DIALOG`,
  CLOSE_TEXT_DIALOG: `${prefix}_CLOSE_TEXT_DIALOG`,
  SET_FILTER: `${prefix}_SET_FILTER`,
  SET_PAGE: `${prefix}_SET_PAGE`,
  SET_ORDER: `${prefix}_SET_ORDER`,
  SET_ORDERBY: `${prefix}_SET_ORDERBY`,
  SET_ROWS_PER_PAGE: `${prefix}_SET_ROWS_PER_PAGE`,
  DELETE_INIT: `${prefix}_DELETE_INIT`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,
  CLOSE_ALL_FORMS: `${prefix}_CLOSE_ALL_FORMS`,
  BACKGROUND_PUBLISHING: `${prefix}_BACKGROUND_PUBLISHING`,
};

const actions = {
  backgroundPublishing: () => ({
    type: actionTypes.BACKGROUND_PUBLISHING,
  }),
  closeAllForms: () => ({
    type: actionTypes.CLOSE_ALL_FORMS,
  }),
  openPostForm: () => ({
    type: actionTypes.SHOW_FORM,
  }),

  openImagePostForm: () => ({
    type: actionTypes.SHOW_IMAGE_FORM,
  }),

  openTextPostDialog: () => ({
    type: actionTypes.SHOW_TEXT_DIALOG,
  }),

  createPost: (data) => ({
    type: actionTypes.CREATE_INIT,
    payload: { data },
  }),

  createSuccess: () => ({
    type: actionTypes.CREATE_SUCCESS,
  }),

  createImagedPost: (data) => ({
    type: actionTypes.CREATE_IMAGED_POST_INIT,
    payload: { data },
  }),

  createImagedSuccess: () => ({
    type: actionTypes.CREATE_IMAGED_POST_SUCCESS,
  }),
  createError: () => ({
    type: actionTypes.CREATE_ERROR,
  }),

  initializeFetch: (
    limit,
    orderBy,
    order,
    page,
    offset = null,
    loadingScreen = true
  ) => ({
    type: actionTypes.FETCH_INIT,
    payload: {
      page,
      limit,
      offset,
      orderBy,
      order,
      loadingScreen,
    },
  }),

  fetchSuccess: (rows, count) => ({
    type: actionTypes.FETCH_SUCCESS,
    payload: {
      rows,
      count,
    },
  }),

  fetchError: () => ({
    type: actionTypes.FETCH_ERROR,
  }),

  deletePosts: (postIds) => ({
    type: actionTypes.DELETE_INIT,
    payload: {
      postIds,
    },
  }),
  deletePostSuccess: () => ({
    type: actionTypes.DELETE_SUCCESS,
  }),

  deletePostError: () => ({
    type: actionTypes.DELETE_ERROR,
  }),

  setFilter: (filter) => ({
    type: actionTypes.SET_FILTER,
    payload: {
      filter,
    },
  }),
  setOrder: (order) => ({
    type: actionTypes.SET_ORDER,
    payload: {
      order,
    },
  }),
  setOrderBy: (orderBy) => ({
    type: actionTypes.SET_ORDERBY,
    payload: {
      orderBy,
    },
  }),
  setPage: (page) => ({
    type: actionTypes.SET_PAGE,
    payload: {
      page,
    },
  }),
  setRowsPerPage: (rowsPerPage) => ({
    type: actionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }),
};

export default actions;
