import { map, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./expenseActions";
import { of } from "rxjs";
import ExpenseRxApiService from "apiServices/expenseRxApiService";

const initialFetchEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      let { limit, order, orderBy, offset } = action.payload;
      return new ExpenseRxApiService()
        .get(state$.value.expense.filter, limit, orderBy, order, offset)
        .pipe(
          map((response) =>
            actions.fetchSuccess(response.data.rows, response.data.count)
          ),
          catchError((err) => {
            console.error(err.response.data);
            return of(actions.fetchError());
          })
        );
    })
  );

const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch(5, "createdAt", "desc", 0))
  );

export default [initialFetchEpic, setFilterEpic];
