import BaseRxApiService from "./baseRxApiService";
import firebase from "firebase/app"
import "firebase/firestore";
import models from "modules/models";
import moment from "moment";
import authAxiosObs from "common/axios/authAxiosObservable";

export default class JobRxApiService extends BaseRxApiService {
  constructor() {
    super(models.JOB);
  }
  jobStream(id) {
    return firebase.firestore().collection(this.documentName).doc(id);
  }
  openJobStream(groupId) {
    return firebase
      .firestore()
      .collection(this.documentName)
      .where("groupId", "==", groupId)
      .where("resolved", "==", false);
  }

  updateReadBy(job) {
    return authAxiosObs.put(`/${models.JOB}/updatereadby`, {
      model: job,
    });
  }
}
