import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Menu, MenuItem, Avatar, makeStyles } from "@material-ui/core";
import authSelectors from "modules/auth/authSelectors";
import groupSelector from "modules/group/groupSelector";
import { getHistory } from "modules/store";
import authActions from "modules/auth/authActions";
import i18next from "i18next";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import Roles from "security/roles";

const styles = makeStyles((theme) => ({
  button: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  text: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: "none",
    color: theme.palette.getContrastText(theme.palette.primary.main),
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  optionText: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
}));

export default function UserMenu() {
  const userText = useSelector(
    authSelectors.selectCurrentUserNameOrEmailPrefix
  );
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const { group } = useSelector(groupSelector.selectGroupConfig);
  const userAvatar = useSelector(authSelectors.selectCurrentUserAvatar);

  const dispatch = useDispatch();
  const classes = styles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doSignout = () => {
    dispatch(authActions.authSignout());
  };

  const navigateToSettings = () => {
    getHistory().push("/settings");
  };

  return (
    <React.Fragment>
      <Button className={classes.button} onClick={handleClick}>
        {userAvatar && <Avatar src={userAvatar} alt="avatar" />}
        {!userAvatar && <AccountCircleIcon />}
        <span className={classes.text}>{userText}</span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <GroupWorkIcon />
          <span className={classes.optionText}>
            Group Id <b>{group.id}</b>
          </span>
        </MenuItem>

        {currentUser.roles.includes(Roles.values.owner) && <MenuItem onClick={navigateToSettings}>
          <SettingsIcon />
          <span className={classes.optionText}>
            {i18next.t("home.options.settings")}
          </span>
        </MenuItem>}
        <MenuItem onClick={doSignout}>
          <ExitToAppIcon />
          <span className={classes.optionText}>
            {i18next.t("auth.signout")}
          </span>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
