import { createSelector } from "reselect";

const selectRaw = (state) => state.layout;

const selectMenuVisible = createSelector(
  [selectRaw],
  (layout) => !!layout.menuVisible
);

const selectRequest = createSelector([selectRaw], (layout) => layout.requesting)

const selectLoading = createSelector([selectRaw], (layout) => !!layout.loading);

const selectLanguage = createSelector([selectRaw], (layout) => layout.language);

const chatDialogs = createSelector([selectRaw], (layout) => layout.openedChats);

const selectors = {
  selectRaw,
  selectMenuVisible,
  selectLoading,
  selectLanguage,
  chatDialogs,
  selectRequest
};

export default selectors;
