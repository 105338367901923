import authAxios from "common/axios/authAxios";

export default class BaseApiService {
  constructor(documentName) {
    this.documentName = documentName;
  }
  create(data) {
    const body = {
      data,
    };
    return authAxios.post(`/${this.documentName}`, body);
  }

  get(filter, limit = null, orderBy = null, order = null, offset = null) {
    const params = { filter, limit, orderBy, order, offset };
    return authAxios.get(`/${this.documentName}`, { params });
  }

  getById(id) {
    return authAxios.get(`/${this.documentName}/${id}`);
  }

  deleteList(ids) {
    return authAxios.delete(`/${this.documentName}`, { data: ids });
  }

  delete(id) {
    return authAxios.delete(`/${this.documentName}/${id}`);
  }

  update(data) {
    return authAxios.put(`/${this.documentName}`, { data: data });
  }
}
