import {
  map,
  mergeMap,
  catchError,
  switchMap,
  mapTo,
  concatMap,
  delay,
  debounceTime,
  filter,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./userActions";
import { of, from, concat } from "rxjs";
import FirebaseFileManager from "@quarkmodule/firebasewrapper/modules/firebaseFileManager";
import Message from "../../view/shared/message";
import UserRxApiService from "../../apiServices/userRxApiService";
import i18next from "i18next";
import UploadUtility from "common/uploadUtility";

/**
 * Gets users and counts from server
 * @param {*} action$
 * @param {*} state$
 */
const getUsersEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      let { limit, order, orderBy, offset } = action.payload;
      return new UserRxApiService()
        .get(state$.value.user.filter, limit, orderBy, order, offset)
        .pipe(
          map((response) => {
            return actions.fetchSuccess(
              response.data.rows,
              response.data.count
            );
          }),
          catchError((error) => {
            console.error(error.response.data);
            Message.error(i18next.t("entities.user.messages.fetcherror"));
            return of(actions.fetchError());
          })
        );
    })
  );

/**
 * Gets simplified users from server
 * @param {*} action$
 */
const getSimplifiedUsersEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.LOAD_SIMPLIFIED_ROWS_INIT),
    mergeMap(() => {
      return new UserRxApiService().getAllSimplifiedUser().pipe(
        map((response) => {
          return actions.loadSimplifiedRowsSuccess(response.data.rows);
        }),
        catchError((error) => {
          console.log(error);
          Message.error(i18next.t("entities.user.messages.simpleUserError"));
          return of(actions.loadSimplifiedRowsError());
        })
      );
    })
  );

/**
 * Create user image with or with out image conditional merge
 * @param {*} action$
 */
const createUserEpic = (action$) => {
  return action$.pipe(
    ofType(actionTypes.CREATE_INIT),
    mergeMap((action) => {
      if (action.payload.data.hasOwnProperty("profileImagePath")) {
        return _createWithImage$(action).pipe(
          mergeMap((_) => {
            Message.success(
              i18next.t("entities.user.messages.userCreateSuccess")
            );
            return of(
              actions.createSuccess(),
              actions.initializeFetch(5, "firstName", "asc", 0)
            );
          }),
          catchError((error) => {
            Message.error(i18next.t("entities.user.messages.userCreateError"));
            console.log(error);
            return of(actions.createError());
          })
        );
      } else {
        return _createWithoutImage$(action).pipe(
          mergeMap((_) => {
            Message.success(
              i18next.t("entities.user.messages.userCreateSuccess")
            );
            return of(
              actions.createSuccess(),
              actions.initializeFetch(5, "firstName", "asc", 0)
            );
          }),
          catchError((error) => {
            Message.error(i18next.t("entities.user.messages.userCreateError"));
            console.log(error);
            return of(actions.createError());
          })
        );
      }
    })
  );
};

/***
 * update last allocated of user when use updates
 */
const tenantUnallocatedEpic = (actions$) =>
  actions$.pipe(
    ofType(actionTypes.UPDATE_ALLOCATION),

    filter((action) => action.payload.modifiedTenants.removed !== undefined),
    mergeMap((action) => from(action.payload.modifiedTenants.removed)),
    mergeMap((v) => {
      return new UserRxApiService().tenantUnallocated(v.tenantId);
    }),
    map(() => actions.updateSuccess())
  );
/***
 * update last allocated of user when use updates
 */
const updateLastAllocatedDateEpic = (actions$) =>
  actions$.pipe(
    ofType(actionTypes.UPDATE_ALLOCATION),
    filter((action) => action.payload.modifiedTenants.added !== undefined),
    mergeMap((action) => from(action.payload.modifiedTenants.added)),
    mergeMap((value) => {
      return new UserRxApiService().updateLastAllocated(
        value.tenantId,
        value.propertyId
      );
    }),
    map(() => actions.updateSuccess())
  );

/**
 * Updates the user with or without image
 * @param {*} action$
 * @param {*} state$
 */

const updateUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_INIT),
    mergeMap((action) => {
      if (
        action.payload.data.hasOwnProperty("profileImagePath") &&
        action.payload.uploadImage
      ) {
        return _updateWithImage$(action).pipe(
          mergeMap(() => {
            Message.success(
              i18next.t("entities.user.messages.userUpdateSuccess")
            );
            return of(
              actions.updateSuccess(),
              actions.initializeFetch(
                state$.value.user.rowsPerPage,
                state$.value.user.orderBy,
                state$.value.user.order,
                state$.value.user.page,
                {
                  startPoint: "at",
                  doc: state$.value.user.rows[0].id,
                },
                false
              )
            );
          }),
          catchError((error) => {
            console.error(error);
            return of(actions.updateError());
          })
        );
      } else {
        return _updateWithoutImage$(action).pipe(
          mergeMap(() => {
            Message.success(
              i18next.t("entities.user.messages.userUpdateSuccess")
            );
            return of(
              actions.updateSuccess(),
              actions.initializeFetch(
                state$.value.user.rowsPerPage,
                state$.value.user.orderBy,
                state$.value.user.order,
                state$.value.user.page,
                {
                  startPoint: "at",
                  doc: state$.value.user.rows[0].id,
                },
                false
              )
            );
          }),
          catchError((error) => {
            console.error(error);
            return of(actions.updateError());
          })
        );
      }
    })
  );

const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch(5, "firstName", "asc", 0))
  );

/**
 * Deletes the users from the server then slowly feeds the ids for image deletion
 * @param {*} action$
 */

const deleteUsersEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.DELETE_INIT),
    switchMap((action) =>
      new UserRxApiService().deleteList(action.payload.userIds).pipe(
        mapTo(action.payload.userIds),

        switchMap((userIds) =>
          from(userIds).pipe(concatMap((userId) => of(userId).pipe(delay(100))))
        ),
        mergeMap((userId) => {
          let user = state$.value.user.rows.find((user) => user.id === userId);
          if (user.path) {
            return from(FirebaseFileManager.delete(user.path)).pipe(
              catchError((error) => {
                console.log(error);
                return of(user);
              })
            );
          } else {
            return of(user);
          }
        }),
        debounceTime(500),
        mergeMap(() => {
          const { rowsPerPage, page, order, orderBy } = state$.value.user;
          Message.success(
            i18next.t("entities.user.messages.userDeleteSuccess")
          );
          return concat(
            of(actions.initializeFetch(rowsPerPage, orderBy, order, page)),
            of(actions.deleteUsersSuccess())
          );
        }),
        catchError((error) => {
          console.log(error);
          Message.error(i18next.t("entities.user.messages.userDeleteError"));
          return of(actions.deleteUsersError());
        })
      )
    )
  );

const _createWithImage$ = (action) =>
  from(
    FirebaseFileManager.upload(
      action.payload.data.profileImagePath,
      action.payload.data.file,
      {
        image: true,
      }
    )
  ).pipe(
    concatMap((uploadResponse) =>
      new UserRxApiService().create({
        ...action.payload.data,
        profileImagePath: UploadUtility.appendResizedFolder(
          uploadResponse.publicUrl,
          uploadResponse.fileName
        ),
        profileImageThumbnailPath: UploadUtility.appendThumbnailFolder(
          uploadResponse.publicUrl,
          uploadResponse.fileName
        ),
      })
    )
  );

const _createWithoutImage$ = (action) =>
  new UserRxApiService()
    .create(action.payload.data)
    .pipe(map((response) => ({ user: response })));

const _updateWithoutImage$ = (action) =>
  new UserRxApiService()
    .update(action.payload.data)
    .pipe(map((response) => ({ user: response })));

const _updateWithImage$ = (action) =>
  from(
    FirebaseFileManager.upload(
      action.payload.data.profileImagePath,
      action.payload.data.file,
      {
        image: true,
      }
    )
  ).pipe(
    concatMap((uploadResponse) =>
      new UserRxApiService().update({
        ...action.payload.data,
        profileImagePath: UploadUtility.appendResizedFolder(
          uploadResponse.publicUrl,
          uploadResponse.fileName
        ),
        profileImageThumbnailPath: UploadUtility.appendThumbnailFolder(
          uploadResponse.publicUrl,
          uploadResponse.fileName
        ),
      })
    )
  );

export default [
  tenantUnallocatedEpic,
  getUsersEpic,
  setFilterEpic,
  deleteUsersEpic,
  createUserEpic,
  updateUserEpic,
  getSimplifiedUsersEpic,
  updateLastAllocatedDateEpic,
];
