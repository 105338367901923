import firebase from "firebase/app"
import "firebase/auth"
import ErrorsHandler from "common/error/errors";
import { throwError } from "rxjs";
import authApiService from "../../apiServices/authApiService";

/** Wrapper class around the firebase auth module */
const apiAuthService = new authApiService();

export default class AuthService {
  static onAuthStateChanged(callbackSuccess, callbackError) {
    return firebase.auth().onAuthStateChanged(callbackSuccess, callbackError);
  }

  static async sendEmailVerification(authenticationUser) {
    let isbackEndConfigued = await apiAuthService.isEmailConfigured();

    let successfullySent = false;
    if (isbackEndConfigued) {
      successfullySent = await apiAuthService.sendEmailVerificationFromBackend();
    } else if (authenticationUser) {
      successfullySent = authenticationUser.sendEmailVerification() == null;
    }
    return successfullySent;
  }

  static async reauthenticateWithStorageToken() {
    try {
      const response = await apiAuthService.getStorageToken();
      const token = response.data;
      return firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      console.error(error);
    }
  }

  static signout() {
    return firebase.auth().signOut();
  }

  static async getCurrentAuthenticatedUser(authenticationUser) {
    let currentUser = null;
    try {
      if (authenticationUser) {
        currentUser = await apiAuthService.fetchMe();

        // in background
        await this.reauthenticateWithStorageToken();

        currentUser.emailVerified = authenticationUser.emailVerified;
      }
      return {
        currentUser,
        authenticationUser,
      };
    } catch (error) {
      ErrorsHandler.handle(error);
      throwError(error);
    }
  }

  static async registerWithEmailAndPassword(email, password) {
    const credential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    return credential.user;
  }

  static async signinWithEmailAndPassword(email, password, rememberMe = false) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    let authenticationUser = null;
    let currentUser = null;

    await firebase.auth().setPersistence(persistence);

    const credentials = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    if (credentials && credentials.user) {
      authenticationUser = credentials.user;
      currentUser = await apiAuthService.fetchMe();
      currentUser.emailVerified = authenticationUser.emailVerified;
    }

    // in background
    this.reauthenticateWithStorageToken();

    return {
      currentUser: currentUser,
      authenticationUser: authenticationUser,
    };
  }

  static async sendPasswordReset(email) {
    await firebase.auth().sendPasswordResetEmail(email);
  }

  static async getCurrentUser() {
    if (firebase.apps.length && firebase.auth().currentUser) {
      return await firebase.auth().currentUser;
    }
    return null;
  }
}
