import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./keyItemActions";
import { of, concat } from "rxjs";
import KeyItemRxApiService from "apiServices/keyItemRxApiService";
import Message from "view/shared/message";
import i18next from "i18next";

const initialFetchEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      let { limit, order, orderBy, offset } = action.payload;
      return new KeyItemRxApiService()
        .get(state$.value.keyitems.filter, limit, orderBy, order, offset)
        .pipe(
          map((response) => {
            return actions.fetchSuccess(response.data.rows, response.data.count)
          }
          ),
          catchError((err) => {
            console.error(err.response.data);
            Message.error(i18next.t("entities.keyItems.messages.fetcherror"));
            return of(actions.fetchError());
          })
        );
    })
  );

const createKeyRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CREATE_INIT),
    mergeMap((action) =>
      new KeyItemRxApiService().create(action.payload.data).pipe(
        mergeMap((response) => {
          Message.success(i18next.t("entities.keyItems.messages.createSuccess"));
          return of(
            actions.createSuccess(),
            actions.initializeFetch(
              state$.value.packages.rowsPerPage,
              "createdAt",
              "desc",
              0,
              null,
              false
            )
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.keyItems.messages.createError"));
          console.error(error);
          return of(actions.createError());
        })
      )
    )
  );

const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch(5, "createdAt", "desc", 0))
  );

const deleteKeyEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.DELETE_INIT),
    tap((stuff) => console.log(stuff)),
    mergeMap((action) =>
      new KeyItemRxApiService().deleteList(action.payload.keyItemIds).pipe(
        mergeMap(() => {
          const { rowsPerPage, page, order, orderBy } = state$.value.post;
          Message.success(
            i18next.t("entities.keyItems.messages.deleteSuccess")
          );
          return concat(
            of(actions.deleteSuccess()),
            of(actions.initializeFetch(rowsPerPage, orderBy, order, page))
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.keyItems.messages.deleteError"));
          return of(actions.deleteError());
        })
      )
    )
  );



export default [
  initialFetchEpic,
  createKeyRequestEpic,
  setFilterEpic,
  deleteKeyEpic
];
