import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import initializers from "./initializers";
import createRootReducer from "./reducers";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddleware from "redux-thunk";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./epics";

const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware({});

let store;

export function configureStore(preloadedState) {
  const middleware = [
    epicMiddleware,
    thunkMiddleware,
    routerMiddleware(history),
  ].filter(Boolean);

  const composeEnhancer = composeWithDevTools({ trace: true });
  store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(...middleware))
  );

  epicMiddleware.run(rootEpic);

  for (const initializer of initializers) {
    initializer(store);
  }

  return store;
}

export function getHistory() {
  return history;
}

export default function getStore() {
  return store;
}
