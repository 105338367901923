const prefix = "COMMUNITY";

export const actionTypes = {
  FETCH_INIT: `${prefix}_FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  CREATE_INIT: `${prefix}_CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,
  UPDATE_INIT: `${prefix}_UPDATE_INIT`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
  SHOW_FORM: `${prefix}_SHOW_FORM`,
  CLOSE_FORM: `${prefix}_CLOSE_FORM`,
  DELETE_INIT: `${prefix}_DELETE_INIT`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,
  BACKGROUND_PUBLISHING: `${prefix}_BACKGROUND_PUBLISHING`,
};

const actions = {
  openCommunityForm: (formValue = null) => ({
    type: actionTypes.SHOW_FORM,
    payload: {
      formValue,
    },
  }),
  closeCommunityForm: () => ({
    type: actionTypes.CLOSE_FORM,
  }),
  createCommunityPost: (data) => ({
    type: actionTypes.CREATE_INIT,
    payload: { data },
  }),
  backgroundPublishing: () => ({
    type: actionTypes.BACKGROUND_PUBLISHING,
  }),
  createSuccess: () => ({
    type: actionTypes.CREATE_SUCCESS,
  }),

  createError: () => ({
    type: actionTypes.CREATE_ERROR,
  }),

  updateCommunityPost: (data) => ({
    type: actionTypes.UPDATE_INIT,
    payload: { data }
  }),

  updateSuccess: () => ({
    type: actionTypes.UPDATE_SUCCESS,
  }),

  updateError: () => ({
    type: actionTypes.UPDATE_ERROR,
  }),

  initializeFetch: (
    limit,
    orderBy,
    order,
    page,
    offset = null,
    loadingScreen = true
  ) => ({
    type: actionTypes.FETCH_INIT,
    payload: {
      page,
      limit,
      offset,
      orderBy,
      order,
      loadingScreen,
    },
  }),

  fetchSuccess: (rows, count) => ({
    type: actionTypes.FETCH_SUCCESS,
    payload: {
      rows,
      count,
    },
  }),

  fetchError: () => ({
    type: actionTypes.FETCH_ERROR,
  }),

  deleteCommunityPost: (communityId) => ({
    type: actionTypes.DELETE_INIT,
    payload: {
      communityId,
    },
  }),

  deleteSuccess: () => ({
    type: actionTypes.DELETE_SUCCESS,
  }),
  deleteError: () => ({
    type: actionTypes.DELETE_ERROR,
  }),
};

export default actions;
