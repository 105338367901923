const prefix = "GROUP";

export const actionTypes = {
  FETCH_INIT: `${prefix}_FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  UPDATE_INIT: `${prefix}_UPDATE_INIT`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
  FETCH_SUBSCRIPTION_INIT: `${prefix}_FETCH_SUBSCRIPTION_INIT`,
  FETCH_SUBSCRIPTION_SUCCESS: `${prefix}_FETCH_SUBSCRIPTION_SUCCESS`,
  FETCH_SUBSCRIPTION_ERROR: `${prefix}_FETCH_SUBSCRIPTION_ERROR`,
};

const actions = {
  getGroup: (id) => ({
    type: actionTypes.FETCH_INIT,
    payload: {
      id,
    },
  }),

  getGroupSuccess: (group) => ({
    type: actionTypes.FETCH_SUCCESS,
    payload: {
      group,
    },
  }),

  updateGroup: (group) => ({
    type: actionTypes.UPDATE_INIT,
    payload: group,
  }),

  updateSuccess: (group) => ({
    type: actionTypes.UPDATE_SUCCESS,
    payload: group,
  }),

  updateError: () => ({
    type: actionTypes.UPDATE_ERROR,
  }),

  getGroupError: () => ({
    type: actionTypes.FETCH_ERROR,
  }),

  getSubscription: (subscriptionId) => ({
    type: actionTypes.FETCH_SUBSCRIPTION_INIT,
    payload: {
      subscriptionId,
    },
  }),

  getSubscriptionSuccess: (subscription) => ({
    type: actionTypes.FETCH_SUBSCRIPTION_SUCCESS,
    payload: subscription,
    
  }),

  getSubscriptionError: () => ({
    type: actionTypes.FETCH_SUBSCRIPTION_ERROR,
  }),
};

export default actions;
