import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import ChatDialogHeader from "./chatDialogHeader";
import layoutSelector from "modules/layout/layoutSelectors";
import { useSelector } from "react-redux";
import ConversationBody from "./conversationBody";
import ChatApiService from "apiServices/chatApiService";
import ChatController from "./chatControllers";
import { createTrimmedDataModelForReadBy } from "common/dataUtility";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "800px",
    borderRadius: "5px",
    boxShadow: "5px 5px 12px -3px rgba(0,0,0,0.63)",
    top: "20%",
    left: "20%",
  },

  hidden: {
    display: "none",
  },
  show: {
    display: "block",
  },

  conversationContent: {
    backgroundColor: theme.palette.info.light,
    height: "500px",
  },
  contentContainer: {
    backgroundColor: "white",
    minWidth: "100%",
    overflow: "auto",
  },
}));

export default function ChatWindow(props) {
  const { chatId, onClose, currentUser, title, participants } = props;

  const [chat, setChat] = useState(null);
  const openedChats = useSelector(layoutSelector.chatDialogs);
  const classes = useStyles();
  const chatService = new ChatApiService();

  useEffect(() => {
    let chatStream;
    chatService.chatStream(chatId).then((stream) => {
      chatStream = stream.onSnapshot((doc) => {
        const tempChat = doc.data();
        let msgs = tempChat.messages;
        if (msgs) {
          msgs.sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });
        } else {
          msgs = [];
        }

        const updatedChat = { ...tempChat, messages: msgs };
        setChat(updatedChat);
        updateScrollPosition();

        // @ts-ignore
        const trimmedChat = createTrimmedDataModelForReadBy(updatedChat);
        chatService.updateReadBy(trimmedChat);
      });
    });
    return () => {
      if (chatStream) {
        chatStream();
      }
    };
  }, []);

  const updateScrollPosition = () => {
    const convoBody = document.getElementById("conversationBody");
    const topPos = convoBody.scrollHeight;
    convoBody.scrollTop = topPos;
  };

  if (!chat) {
    return <CircularProgress />;
  }

  const currentChatIsSelected = openedChats.some((c) => chatId === c.id);

  return (
    <div className={currentChatIsSelected ? classes.show : classes.hidden}>
      <Draggable handle=".handle" grid={[1, 1]} scale={1}>
        <div className="handle">
          <Grid className={classes.root} container direction="column">
            <ChatDialogHeader
              onClose={() => onClose(chat.id)}
              chat={chat}
              title={title}
            />
            <Grid
              className={classes.conversationContent}
              item
              container
              direction="column"
            >
              <ConversationBody
                participants={participants}
                currentUser={currentUser}
                chat={chat}
                parentClass={classes}
              />

              <ChatController
                currentUser={currentUser}
                chat={chat}
                parentClass={classes}
              />
            </Grid>
          </Grid>
        </div>
      </Draggable>
    </div>
  );
}
