import React from "react";
let notistakEnqueueSnackbar = null;

export default class Message {
  static registerNotistakEnqueueSnackbar(instance) {
    notistakEnqueueSnackbar = instance;
  }

  static info(arg) {
    let notificationContent;
    if (arg.header) {
      notificationContent =
        <div>
          <p>
            <b>{arg.header}</b>
          </p>
          <div style={{ wordWrap: "break-word", width: "200px", paddingBottom: "20px" }}>
            {arg.message}
          </div>
        </div>
    } else {
      notificationContent = <span>{arg}</span>
    }
    notistakEnqueueSnackbar(
      notificationContent
      , {
        variant: "info", anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
  }

  static success(arg) {
    notistakEnqueueSnackbar(arg, { variant: "success" });
  }

  static warning(arg) {
    notistakEnqueueSnackbar(arg, { variant: "warning" });
  }

  static error(arg) {
    notistakEnqueueSnackbar(arg, { variant: "error" });
  }
}
