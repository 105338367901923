import authAxios from "common/axios/authAxios";

export default class AppApiService {
  static async checkAppState() {
    const response = await authAxios.get("/app/stateCheck");
    return response.data;
  }

  static async sendErrorEmail(action, error) {
    const data = {
      action, error
    }
    await authAxios.post("/app/errorEmail", data);
  }
}
