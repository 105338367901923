import { Button, Checkbox, Grid, makeStyles, OutlinedInput } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SendIcon from "@material-ui/icons/Send";
import ChatApiService from "apiServices/chatApiService";
import { createTrimmedModelForSendMessage } from "common/dataUtility";
import { useSelector } from "react-redux";
import userSelector from "modules/user/userSelector";
import authSelector from "modules/auth/authSelectors"


const useStyles = makeStyles(() => ({
  root: {
    minWidth: "100%",
    borderTop: "1px solid lightgrey",
  },
  textField: { paddingLeft: "20px" },
  sendButton: { color: "white", minHeight: "60px", margin: "8px" },
  sendOnEnterLabel: { color: "black" }
}));
export default function ChatController(props) {
  const classes = useStyles();
  const { parentClass, chat } = props;
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [emailString, setEmailString] = useState("");
  const [sendOnEnter, setSendOnEnter] = useState(true);
  const { simplifiedRows } = useSelector(userSelector.selectSimplifiedRows);
  const currentUser = useSelector(authSelector.selectCurrentUser);
  const chatService = new ChatApiService();

  useEffect(() => {
    const participants = simplifiedRows.users.filter(u => u.id !== currentUser.id && chat.participantIds.includes(u.id));
    const emails = participants.map(u => u.email);
    const emailStringTemp = emails.length > 1 ? emails.join(",") : emails[0];
    setEmailString(emailStringTemp);
  }, [simplifiedRows])

  const submitMessage = async () => {
    setSending(true);
    const trimmedChat = createTrimmedModelForSendMessage(chat);
    await chatService.sendMessage(trimmedChat, message);
    setMessage("");
    setSending(false);
  };

  const onKeyDown = async (event) => {
    if (event.keyCode === 13 && sendOnEnter) {
      event.preventDefault();
      await submitMessage();
    }
  };

  const onSendOnEnterChange = (event) => {
    const isChecked = event.target.checked;
    setSendOnEnter(isChecked);
  }

  return (
    <Grid item xs={4} lg={4} container className={parentClass.contentContainer}>
      <Grid
        container
        item
        className={classes.root}
        alignItems="center"
        alignContent="center"
        justifyContent="space-between"
      >
        <Grid className={classes.textField} xs={9} item>
          <OutlinedInput
            onKeyDown={onKeyDown}
            onChange={(event) => setMessage(event.target.value)}
            value={message}
            autoFocus
            id="name"
            type="text"
            multiline={true}
            rowsMin={3}
            maxRows={5}
            fullWidth
            notched={false}
            minRows={4}
          />
        </Grid>
        <Grid xs={3} item container justifyContent="center" direction="column">
          <Grid item container alignItems="center" justifyContent="center">
            <Grid item >
              <Button
                onClick={submitMessage}
                disabled={message.trim().length === 0 || sending}
                className={classes.sendButton}
                endIcon={<SendIcon />}
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid>
                <Checkbox checked={sendOnEnter} onChange={onSendOnEnterChange} />
              </Grid>
              <Grid>
                <span className={classes.sendOnEnterLabel}>
                  Send on Enter
                </span>
              </Grid>

            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" justifyContent="space-evenly">
              <Grid>
                <a href={`mailto:?bcc=${emailString}`}><img alt="emailImage" height="20px" src="images/email.png" /></a>
              </Grid>
              <Grid>
                <span className={classes.sendOnEnterLabel}>
                  Email
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}


