import { propertyChatTypes } from "common/enums";

export default class ChatModel {
  constructor(
    chatId,
    participantIds,
    groupId,
    chatType,
    createdBy,
    enableResponse = true,
    propertyIds = null,
    propertyId = null,
    propertyChatType = propertyChatTypes.OTHER,
  ) {
    this.chatId = chatId;
    this.participantIds = participantIds;
    this.groupId = groupId;
    this.chatType = chatType;
    this.createdBy = createdBy;
    this.createdAt = new Date().toISOString();
    this.readBy = [createdBy];
    this.enableResponse = enableResponse;
    this.propertyIds = propertyIds;
    this.propertyId = propertyId
    this.propertyChatType = propertyChatType;
  }
}
