import { map, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./keyRequestLogsActions";
import { of } from "rxjs";
import KeyRequestLogRxApiService from "apiServices/keyRequestLogsRxApiService";
import Message from "view/shared/message";
import i18next from "i18next";

const initialFetchEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      let { limit, order, orderBy, offset } = action.payload;
      return new KeyRequestLogRxApiService()
        .get(state$.value.keyrequestlogs.filter, limit, orderBy, order, offset)
        .pipe(
          map((response) =>
            actions.fetchSuccess(response.data.rows, response.data.count)
          ),
          catchError((err) => {
            console.error(err.response.data);
            Message.error(i18next.t("entities.keyRequestLogs.messages.fetcherror"));
            return of(actions.fetchError());
          })
        );
    })
  );


const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch(5, "createdAt", "desc", 0))
  );



export default [
  initialFetchEpic,
  setFilterEpic,
];
