import authAxiosObs from "common/axios/authAxiosObservable";
import models from "modules/models";

export default class KeyItemRxApiSerivce {
  constructor() {
    this.documentName = models.KEYITEM;
  }
  create(data) {
    const body = {
      data,
    };
    return authAxiosObs.post(`/${this.documentName}`, body);
  }

  get(filter, limit = null, orderBy = null, order = null, offset = null) {
    const params = { filter, limit, orderBy, order, offset };
    return authAxiosObs.get(`/${this.documentName}`, { params });
  }

  getByPropertyId(id) {
    return authAxiosObs.get(`/${this.documentName}/${id}`);
  }


  deleteList(ids) {
    return authAxiosObs.delete(`/${this.documentName}`, { data: ids });
  }

}
