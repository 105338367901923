import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useDispatch } from "react-redux";
import layoutActions from "modules/layout/layoutActions";
import { Typography } from "@material-ui/core";
import i18next from "i18next";
import ChatWindow from "./chatComponents/chatWindow";
import ChatInbox from "./chatComponents/chatInbox";
import layoutSelector from "modules/layout/layoutSelectors";
import { useSelector } from "react-redux";
import authSelector from "modules/auth/authSelectors";

const drawerWidth = 400;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  unread: {
    color: theme.palette.info.main,
  },
  chatItem: {
    backgroundColor: "white",
    minHeight: "70px",
  },
}));

export default function ChatDrawer(props) {
  const classes = useStyles();
  const { open, handleDrawerClose, chats } = props;
  const openedChats = useSelector(layoutSelector.chatDialogs);
  const currentUser = useSelector(authSelector.selectCurrentUser);
  const dispatch = useDispatch();
  const chatItemSelected = (chatItem) => {
    if (openedChats.some((chat) => chat.id === chatItem.id)) {
      console.log("chat Item already exists");
      return;
    }
    dispatch(layoutActions.openChat(chatItem));
  };

  const onChatItemClosed = (chatId) => {
    dispatch(layoutActions.closeChat(chatId));
  };

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
          <Typography variant="h5">
            {i18next.t("layout.headers.conversations")}
          </Typography>
        </div>
        <Divider />
        <ChatInbox
          currentUser={currentUser}
          chats={chats}
          chatItemSelected={chatItemSelected}
        />
      </Drawer>
      {openedChats.map((chat) => (
        <div key={chat.id} id={chat.id}>
          <ChatWindow
            currentUser={currentUser}
            chatId={chat.id}
            title={chat.title}
            participants={chat.participants}
            onClose={onChatItemClosed}
          />
        </div>
      ))}
    </Fragment>
  );
}
