import { actionTypes } from "./propertyActions";

const initialData = {
  rows: [],
  unfilteredRows: [],
  loadingUnfilteredRows: false,
  loadingProperties: true,
  initialFormValue: null,
  filter: {},
  showFormModal: false,
  count: 0,
  page: 0,
  rowsPerPage: 5,
};

export default (state = initialData, action) => {
  switch (action.type) {
    case actionTypes.SHOW_FORM:
      return {
        ...state,
        initialFormValue: action.payload.initialFormValue,
        showFormModal: true,
      };
    case actionTypes.CLOSE_FORM:
      return {
        ...state,
        initialFormValue: null,
        showFormModal: false,
      };
    case actionTypes.FETCH_INIT:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.limit,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
        loadingProperties: action.payload.loadingScreen,
        loadingUnfilteredRows: action.payload.refreshUnfiltered,
      };
    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loadingProperties: false,
        rows: action.payload.rows,
        count: action.payload.count,
        page: action.payload.page,
      };
    case actionTypes.CREATE_SUCCESS:
    case actionTypes.CREATE_ERROR:
    case actionTypes.UPDATE_ERROR:
    case actionTypes.UPDATE_SUCCESS:
    case actionTypes.DELETE_ERROR:
    case actionTypes.DELETE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
      };

    case actionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };

    case actionTypes.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter,
      };
    }

    case actionTypes.SET_UNFILTERED_ROW: {
      return {
        ...state,
        unfilteredRows: action.payload.data,
        loadingUnfilteredRows: false,
      };
    }
    case actionTypes.CLEAR_CACHE: {
      return initialData;
    }
    default:
      return state;
  }
};
