import actions from "modules/auth/authActions";
import service from "modules/auth/authService";

export default (store) => {
  const unsubscribe = service.onAuthStateChanged(
    (authenticationUser) => {
      store.dispatch(actions.authInit(authenticationUser));
      unsubscribe();
    },
    (error) => {
      store.dispatch(actions.authInitError);
    }
  );
};
