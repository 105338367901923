const prefix = "PROPERTY";

export const actionTypes = {
  FETCH_INIT: `${prefix}_FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  CREATE_INIT: `${prefix}_CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,
  DELETE_INIT: `${prefix}_DELETE_INIT`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,
  SET_UNFILTERED_ROW: `${prefix}_SET_UNFILTERED_ROW`,
  UPDATE_INIT: `${prefix}_UPDATE_INIT`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
  SET_FILTER: `${prefix}_SET_FILTER`,
  SET_PAGE: `${prefix}_SET_PAGE`,
  SET_ORDER: `${prefix}_SET_ORDER`,
  SET_ORDERBY: `${prefix}_SET_ORDERBY`,
  SET_ROWS_PER_PAGE: `${prefix}_SET_ROWS_PER_PAGE`,
  SHOW_FORM: `${prefix}_SHOW_FORM`,
  CLOSE_FORM: `${prefix}_CLOSE_FORM`,
  CLEAR_CACHE: `${prefix}_CLEAR_CACHE`,
};

const actions = {
  initializeFetch: (
    orderBy,
    order,
    page = 0,
    loadingScreen = true,
    refreshUnfiltered = false
  ) => ({
    type: actionTypes.FETCH_INIT,
    payload: {
      page,
      orderBy,
      order,
      loadingScreen,
      refreshUnfiltered,
    },
  }),

  fetchSuccess: (rows, count, page = 0) => ({
    type: actionTypes.FETCH_SUCCESS,
    payload: {
      rows,
      count,
      page,
    },
  }),

  fetchError: () => ({
    type: actionTypes.FETCH_ERROR,
  }),

  createProperty: (data, tenantsModified = []) => ({
    type: actionTypes.CREATE_INIT,
    payload: {
      data,
      tenantsModified,
    },
  }),
  createSuccess: () => ({
    type: actionTypes.CREATE_SUCCESS,
  }),

  createError: () => ({
    type: actionTypes.CREATE_ERROR,
  }),

  deleteProperty: (id) => ({
    type: actionTypes.DELETE_INIT,
    payload: {
      id,
    },
  }),

  deleteSuccess: () => ({
    type: actionTypes.DELETE_SUCCESS,
  }),

  deleteError: () => ({
    type: actionTypes.DELETE_ERROR,
  }),

  updateProperty: (data, tenantsModified = []) => ({
    type: actionTypes.UPDATE_INIT,
    payload: {
      data,
      tenantsModified,
    },
  }),

  updateSuccess: () => ({
    type: actionTypes.UPDATE_SUCCESS,
  }),

  updateError: () => ({
    type: actionTypes.UPDATE_ERROR,
  }),

  setFilter: (filter) => ({
    type: actionTypes.SET_FILTER,
    payload: {
      filter,
    },
  }),

  setUnfilteredRows: (data) => ({
    type: actionTypes.SET_UNFILTERED_ROW,
    payload: {
      data,
    },
  }),

  setPage: (page) => ({
    type: actionTypes.SET_PAGE,
    payload: {
      page,
    },
  }),
  openPropertyForm: (initialFormValue = null) => ({
    type: actionTypes.SHOW_FORM,
    payload: { initialFormValue },
  }),

  closePropertyForm: () => ({
    type: actionTypes.CLOSE_FORM,
  }),

  clearCache: () => ({
    type: actionTypes.CLEAR_CACHE,
  }),
};

export default actions;
