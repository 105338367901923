import { actionTypes } from "modules/auth/authActions";

const initialData = {
  authenticationUser: null,
  currentUser: null,
  loadingInit: true,
  loadingEmailConfirmation: false,
  loadingPasswordResetEmail: false,
  loadingUpdateProfile: false,
  disableSendVerificationEmail: false,
  loading: false,
  errorMessage: null,
};

export default (state = initialData, action) => {
  switch (action.type) {
    case actionTypes.EMAIL_CONFIRMATION_START:
      return {
        ...state,
        loadingEmailConfirmation: true,
      };
    case actionTypes.EMAIL_CONFIRMATION_COMPLETE:
    case actionTypes.EMAIL_CONFIRMATION_FAIL:
      return {
        ...state,
        loadingEmailConfirmation: false,
      };
    case actionTypes.AUTH_INIT_SUCCESS:
      return {
        ...state,
        authenticationUser: action.payload.authenticationUser || null,
        currentUser: action.payload.currentUser || null,
        loadingInit: false,
      };
    case actionTypes.AUTH_INIT_ERROR:
      return {
        ...state,
        authenticationUser: null,
        currentUser: null,
        loadingInit: false,
      };

    case actionTypes.ERROR_MESSAGE_CLEARED:
      return {
        ...state,
        errorMessage: null,
      };
    case actionTypes.AUTH_START:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        authenticationUser: action.payload.authenticationUser || null,
        currentUser: action.payload.currentUser || null,
        errorMessage: null,
        loading: false,
      };
    case actionTypes.AUTH_ERROR:
      return {
        ...state,
        authenticationUser: null,
        currentUser: null,
        errorMessage: action.payload || null,
        loading: false,
      };

    case actionTypes.REGISTER_USER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.EMAIL_VERIFICATION_START:
      return {
        ...state,
        disableSendVerificationEmail: true,
      };
    case actionTypes.EMAIL_VERIFICATION_COMPLETE: {
      return {
        ...state,
        disableSendVerificationEmail: false,
      };
    }

    default:
      return state;
  }
};
