import authAxios from "common/axios/authAxios";
import models from "modules/models";

export default class authApiService {
  async fetchMe() {
    const response = await authAxios.get(`/${models.AUTH}/me`);
    return response.data;
  }
  async isEmailConfigured() {
    const response = await authAxios.get(`/${models.AUTH}/email-configured`);
    return response.data;
  }

  async getStorageToken() {
    const response = await authAxios.get(`/${models.AUTH}/storage-token`);
    return response;
  }

  async sendPasswordResetEmailFromBackend(email) {
    const response = await authAxios.post(
      `/${models.AUTH}/send-password-reset-email`,
      {
        email,
      }
    );
    return response.data;
  }

  async sendEmailVerificationFromBackend() {
    const response = await authAxios.post(
      `/${models.AUTH}/send-email-address-verification-email`
    );
    return response.data;
  }
}
