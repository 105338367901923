import { actionTypes } from "modules/layout/layoutActions";
import i18next from "i18next";
import * as _ from "lodash";

const initialData = {
  menuVisible: false,
  language: i18next.language,
  loading: false,
  outdatedData: true,
  openedChats: [],
  requesting: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actionTypes.MENU_TOGGLE) {
    return {
      ...state,
      menuVisible: !state.menuVisible,
    };
  }

  if (type === actionTypes.REQUESTING_START) {
    return {
      ...state,
      requesting: true,
    };
  }

  if (type === actionTypes.REQUESTING_END) {
    return {
      ...state,
      requesting: false,
    };
  }

  if (type === actionTypes.MENU_SHOW) {
    return {
      ...state,
      menuVisible: true,
    };
  }

  if (type === actionTypes.MENU_HIDE) {
    return {
      ...state,
      menuVisible: false,
    };
  }

  if (type === actionTypes.DATA_UPDATED) {
    return {
      ...state,
      outdatedData: false,
    };
  }

  if (type === actionTypes.DATA_OUTDATED) {
    return {
      ...state,
      outdatedData: true,
    };
  }

  if (type === actionTypes.CREATE_OR_OPEN_CHAT) {
    return {
      ...state,
      requesting: true,
    };
  }

  if (type === actionTypes.OPEN_CHAT) {
    return {
      ...state,
      openedChats: [...state.openedChats, payload],
      requesting: false,
    };
  }

  if (type === actionTypes.CLOSE_CHAT) {
    _.remove(state.openedChats, (c) => c.id === payload);
    return {
      ...state,
      openedChats: [...state.openedChats],
    };
  }

  return state;
};
