import { map, mergeMap, catchError, delay } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./communityActions";
import { of } from "rxjs";
import Message from "../../view/shared/message";
import CommunityRxApiService from "../../apiServices/communityRxApiService";
import i18next from "i18next";

const getCommunityEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((_) => {
      return new CommunityRxApiService().get(null, null, "order", "asc").pipe(
        map((response) => {
          return actions.fetchSuccess(response.data.rows, response.data.count);
        }),
        catchError((error) => {
          console.error(error.response.data);
          Message.error(i18next.t("entities.community.messages.fetchError"));
          return of(actions.fetchError());
        })
      );
    })
  );

/**
 * Create user image with or with out image conditional merge
 * @param {*} action$
 */
const createCommunityEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.CREATE_INIT),
    delay(6000), // Allow time for processing of images
    mergeMap((action) =>
      new CommunityRxApiService().create(action.payload.data).pipe(
        mergeMap((_) => {
          Message.success(i18next.t("entities.community.messages.createSuccess"));
          return of(
            actions.createSuccess(),
            actions.initializeFetch(null, "order", "asc", 0)
          );
        }),
        catchError((error) => {
          if (error.response.status === 409) {
            Message.warning(
              i18next.t("entities.community.messages.duplicateError")
            );
          } else {
            Message.error(i18next.t("entities.community.messages.createError"));
          }
          return of(actions.createError());
        })
      )
    )
  );

/**
 * Updates the user with or without image
 * @param {*} action$
 * @param {*} state$
 */
const updateCommunityEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_INIT),
    mergeMap((action) =>
      new CommunityRxApiService().update(action.payload.data).pipe(
        mergeMap((_) => {
          Message.success(i18next.t("entities.community.messages.updatesuccess"));
          return of(
            actions.updateSuccess(),
            actions.initializeFetch(null, "order", "asc", 0)
          );
        }),
        catchError((error) => {
          console.log(error);
          Message.error(i18next.t("entities.community.messages.updateerror"));
          return of(actions.updateError());
        })
      )
    )
  );

const deleteCommunityEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.DELETE_INIT),
    mergeMap((action) =>
      new CommunityRxApiService().delete(action.payload.communityId).pipe(
        mergeMap((_) => {
          Message.success(i18next.t("entities.community.messages.deleteSuccess"));
          return of(
            actions.deleteSuccess(),
            actions.initializeFetch(null, "order", "asc", 0)
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.community.messages.deleteError"));
          console.error(error);
          return of(actions.deleteError());
        })
      )
    )
  );

export default [
  getCommunityEpic,
  createCommunityEpic,
  deleteCommunityEpic,
  updateCommunityEpic,
];
