import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const chatBubbleCss = {
  color: "white",
  maxWidth: "50%",
  borderRadius: "10px",
  margin: "20px",
  padding: "10px",
};
const useStyles = makeStyles(() => ({
  root: {
    minWidth: "100%",
    minHeight: "100%",
  },
  meBubble: {
    ...chatBubbleCss,
    backgroundColor: "darkslateblue",
  },
  elseBubble: {
    ...chatBubbleCss,
    backgroundColor: "#F66A6D",
  },

  chatBubbleTitle: {
    fontWeight: "bold",
  },
  chatBubbleText: {
    wordWrap: "break-word",
    width: "200px",
    marginTop: "5px",
  },
  chatRow: {

  },
}));
export default function ConversationBody(props) {
  const { chat, parentClass, currentUser, participants } = props;
  const classes = useStyles();

  return (
    <Grid
      item
      xs={8}
      lg={8}
      container
      className={parentClass.contentContainer}
      id="conversationBody"
    >
      <Grid
        container
        item
        direction="column-reverse"
        className={classes.root}
      >
        {chat.messages.map((msg) => {
          return (
            <Grid
              className={classes.chatRow}
              key={msg.id}
              container
              item
              direction="column"
              alignContent={
                msg.createdBy === currentUser.id ? "flex-end" : "flex-start"
              }
            >
              <Grid
                item
                direction="column"
                container
                className={
                  msg.createdBy === currentUser.id
                    ? classes.meBubble
                    : classes.elseBubble
                }
              >
                <Grid item className={classes.chatBubbleTitle}>
                  {participants.get(msg.createdBy) == null
                    ? "Unknown User"
                    : participants.get(msg.createdBy)}
                </Grid>
                <Grid item className={classes.chatBubbleText}>
                  {msg.text}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
