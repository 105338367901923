import { chatType, propertyChatTypes } from "common/enums";
import UserStateService from "modules/user/userStateService";
import { propertyToString } from "view/formModels/helpers/propertyHelper";
import ChatModel from "./model/chatModel";
import * as _ from "lodash";
import { propertyChatTypeAsString } from "view/formModels/helpers/chatHelper";

export default class ChatService {
  static createUserChatModel(currentUser, participant) {
    const chatId = userChatIdGenetator(currentUser, participant);
    const chatModel = new ChatModel(
      chatId,
      [currentUser.id, participant.id],
      currentUser.groupId,
      chatType.USER,
      currentUser.id
    );
    return chatModel;
  }
  static createPropertyChatModel(currentUser, appManagerIds, property, propertyChatType) {
    let participantIds = [];
    switch (propertyChatType) {
      case propertyChatTypes.ALL:
        participantIds = _.uniq([currentUser.id, ...property.tenants, ...property.landlords, ...appManagerIds]);
        break;
      case propertyChatTypes.LANDLORD:
        participantIds = _.uniq([currentUser.id, ...property.landlords, ...appManagerIds]);
        break;
      case propertyChatTypes.TENANT:
        participantIds = _.uniq([currentUser.id, ...property.tenants, ...appManagerIds]);
        break;

      default:
        break;
    }
    const chatId = `${property.id}-${propertyChatType}`;
    const chatModel = new ChatModel(
      chatId,
      participantIds,
      currentUser.groupId,
      chatType.PROPERTY,
      currentUser.id,
      true,
      null,
      property.id,
      propertyChatType,
    );
    return chatModel;
  }

  static createGroupPropertyChatModel(
    currentUser,
    appManagerIds,
    properties,
    chatId,
    enableResponse
  ) {
    const tenants = properties.filter(p => p.tenants.length > 0).map((p) => p.tenants);
    const propertyIds = properties.map((p) => p.id);
    const participantIds = _.uniq(_.concat([currentUser.id, ...appManagerIds], ...tenants));
    const chatModel = new ChatModel(
      chatId,
      participantIds,
      currentUser.groupId,
      chatType.GROUP,
      currentUser.id,
      enableResponse,
      propertyIds
    );
    return chatModel;
  }
  /**
   * Populate chat with title and fully hydrated user objects
   * @param {*} chat
   * @param {*} currentUser
   * @param {*} properties
   * @param {*} simplifiedRows
   */
  static populateChatObject(chat, currentUser, properties, simplifiedRows) {
    const userStateService = new UserStateService(simplifiedRows.users);
    const title = determineChatTitle(
      chat,
      currentUser,
      userStateService,
      properties
    );

    let participantMapping = new Map();

    chat.participantIds.forEach((id) => {
      var participant = userStateService.findById(id);
      participantMapping.set(id, participant.name);
    });

    chat.title = title;
    chat.participants = participantMapping;
    return chat;
  }
}

const determineChatTitle = (
  chat,
  currentUser,
  userStateService,
  properties
) => {
  switch (chat.chatType) {
    case chatType.USER:
      var otherParticipantId = chat.participantIds.find(
        (id) => id !== currentUser.id
      );
      var participant = userStateService.findById(otherParticipantId);
      return participant.name;

    case chatType.PROPERTY:
      var property = properties.find((p) => p.id === chat.propertyId);

      if (!property) {
        return "Unknown Property";
      } else {
        return propertyToString(property) + ` - ${propertyChatTypeAsString(chat.propertyChatType)}`;
      }
    case chatType.GROUP:
      return chat.chatId;
    default:
      return "Unknown";
  }
};


const userChatIdGenetator = (participantA, participantB) => {
  let users = [participantA.id, participantB.id];
  users.sort();
  const chatId = users.join("");
  return chatId;
};
