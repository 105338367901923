import { map, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./groupActions";
import { of } from "rxjs";
import GroupApiService from "apiServices/groupApiService";
import SubscriptionApiService from "apiServices/subscriptionApiService";
import Message from "view/shared/message";
import i18next from "i18next";

const initialFetchEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_INIT),
    mergeMap((action) => {
      return new GroupApiService().getById(action.payload.id).pipe(
        mergeMap((response) =>
          of(
            actions.getGroupSuccess(response.data),
            actions.getSubscription(response.data.subscriptionId)
          )
        ),
        catchError((err) => {
          console.error(err.response.data);
          Message.error(i18next.t("entities.group.messages.fetcherror"));
          return of(actions.getGroupError());
        })
      );
    })
  );

const updateGroupEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_INIT),
    mergeMap((action) => {
      return new GroupApiService().update(action.payload).pipe(
        mergeMap((response) =>
          of(actions.updateSuccess(), actions.getGroup(action.payload.id))
        ),
        catchError((err) => {
          console.error(err);
          Message.error(i18next.t("entities.group.messages.fetcherror"));
          return of(actions.getGroupError());
        })
      );
    })
  );

const initialFeatureFetchEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.FETCH_SUBSCRIPTION_INIT),
    mergeMap((action) => {
      return new SubscriptionApiService()
        .getSubscriptionById(action.payload.subscriptionId)
        .pipe(
          map((response) => actions.getSubscriptionSuccess(response.data)),
          catchError((err) => {
            console.error(err);
            Message.error(
              i18next.t("entities.group.messages.fetchFeatureError")
            );
            return of(actions.getSubscriptionError());
          })
        );
    })
  );

export default [initialFetchEpic, initialFeatureFetchEpic, updateGroupEpic];
