import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import layout from "modules/layout/layoutReducers";
import auth from "modules/auth/authReducers";
import packages from "modules/package/packageReducer";
import keyitems from "modules/keyItem/keyItemReducer";
import keyrequest from "modules/keyRequest/keyRequestReducer";
import keyrequestlogs from "modules/keyRequestLogs/keyRequestLogsReducer";
import property from "modules/property/propertyReducer";
import post from "modules/post/postReducer";
import user from "modules/user/userReducer";
import issue from "modules/issue/issueReducer";
import service from "modules/service/serviceReducer";
import booking from "modules/booking/bookingReducer";
import group from "modules/group/groupReducer";
import expense from "modules/expense/expenseReducer";
import community from "modules/community/communityReducer";
import job from "modules/job/jobReducer";


const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    packages,
    post,
    property,
    user,
    issue,
    service,
    booking,
    group,
    expense,
    community,
    keyitems,
    keyrequest,
    keyrequestlogs,
    job
  });
export default createRootReducer;
