import i18next from "i18next";
import _values from "lodash/values";

class Roles {
  static get values() {
    return {
      owner: "owner",
      appManager: "appManager",
      editor: "editor",
      viewer: "viewer",
      tenant: "tenant",
      landlord: "landlord",
      contractor: "contractor",
      packageOwner: "packageOwner",
      packageEditor: "packageEditor",
      packageViewer: "packageViewer",
      propertyOwner: "propertyOwner",
      propertyEditor: "propertyEditor",
      propertyViewer: "propertyViewer",
      keyManagementOwner: "keyManagementOwner",
      keyManagementEditor: "keyManagementEditor",
      keyManagementViewer: "keyManagementViewer",
      issueOwner: "issueOwner",
      issueEditor: "issueEditor",
      issueViewer: "issueViewer",
      jobOwner: "jobOwner",
      jobEditor: "jobEditor",
      jobViewer: "jobViewer",
      bookingOwner: "bookingOwner",
      bookingEditor: "bookingEditor",
      bookingViewer: "bookingViewer",
      serviceOwner: "serviceOwner",
      serviceEditor: "serviceEditor",
      serviceViewer: "serviceViewer",
      communityOwner: "communityOwner",
      communityEditor: "communityEditor",
      communityViewer: "communityViewer",
      postOwner: "postOwner",
      postEditor: "postEditor",
      postViewer: "postViewer",
      expenseOwner: "expenseOwner",
      expenseEditor: "expenseEditor",
      expenseViewer: "expenseViewer",
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18next.t(`roles.label.${roleId}`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18next.t(`roles.${roleId}.description`);
  }

  static get selectOptions() {
    return _values(this.values).map((value) => ({
      id: value,
      value: value,
      title: this.descriptionOf(value),
      label: this.labelOf(value),
    }));
  }
}

export default Roles;
