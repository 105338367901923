export default Object.freeze({
  PACKAGE: "package",
  BOOKING: "booking",
  SERVICE: "service",
  COMMUNITY: "communityPost",
  POST: "post",
  PROPERTY: "property",
  ISSUE: "issue",
  JOB: "job",
  EXPENSE: "expense",
  AUTH: "auth",
  CHAT: "chat",
  USER: "user",
  GROUP: "group",
  KEYREQUEST: "keyRequest",
  KEYITEM: "keyItem",
  KEYREQUESTLOG: "keyCollectionLog"
});
