import authAxiosObs from "common/axios/authAxiosObservable";
import models from "modules/models";

export default class GroupApiService {
  getById(id) {
    return authAxiosObs.get(`/${models.GROUP}/${id}`);
  }

  update(data) {
    return authAxiosObs.put(`/${models.GROUP}`, { data: data });
  }

  create(data) {
    return authAxiosObs.post(`/${models.GROUP}`, { data: data });
  }
}
