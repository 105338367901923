import { actionTypes } from "./keyRequestLogsActions";

const intitialState = {
  rows: [],
  loadingLogs: false,
  filter: {},
  count: 0,
  order: "asc",
  orderBy: "createdAt",
  page: 0,
  rowsPerPage: 5,
};

export default (state = intitialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_INIT:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.limit,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
        loadingLogs: action.payload.loadingScreen,
      };
    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loadingLogs: false,
        rows: action.payload.rows,
        count: action.payload.count,
      };
    case actionTypes.FETCH_ERROR:
      return {
        ...state,
        loadingLogs: false,
      };
    case actionTypes.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    case actionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };

    case actionTypes.SET_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };

    case actionTypes.SET_ORDERBY:
      return {
        ...state,
        orderBy: action.payload.orderBy,
      };

    case actionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
      };
    default:
      return state;
  }
};
