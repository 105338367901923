import firebase from "firebase/app"

/* Returns the auth token for the current user if app has been initialized and user is signed in */

export class AuthToken {
  static async get() {
    if (firebase.apps.length && firebase.auth().currentUser) {
      return await firebase.auth().currentUser.getIdToken(true);
    }
    return null;
  }
}
