import { AuthToken } from "modules/auth/authToken";
import config from "../../config";
import Qs from "qs";
import * as moment from "moment";
import AxiosObs from "axios-observable";
import i18next from "i18next";

// Create an axios instance with filter process for change date and time format in request parameters

const authAxios = AxiosObs.create({
  baseURL: config.backendUrl,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      filter: (prefix, value) => {
        if (value instanceof moment || value instanceof Date) {
          // @ts-ignore
          return value.toISOString();
        }

        return value;
      },
    });
  },
});

// intercept the requests and inject JWT token

authAxios.interceptors.request.use(
  async (req) => {
    const token = await AuthToken.get();

    if (token) {
      req.headers.authorization = `Bearer ${token}`;
    } else {
      req.headers.preauthtoken = config.preauthToken;
    }
    req.headers["Accept-Language"] = i18next.language;
    return req;
  },
  function (error) {
    console.log("Request error: ", error);
    return Promise.reject(error);
  }
);

export default authAxios;
