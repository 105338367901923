import { actionTypes } from "./communityActions";

const intitialState = {
  rows: [],
  loadingCommunityPost: false,
  showFormModal: false,
  initialFormValue: null,
  backgroundPublishing: false,
};

export default (state = intitialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_FORM:
      return {
        ...state,
        showFormModal: true,
        initialFormValue: action.payload.formValue,
      };
    case actionTypes.CLOSE_FORM:
      return {
        ...state,
        showFormModal: false,
        initialFormValue: null,
      };
    case actionTypes.CREATE_INIT:
      return {
        ...state,
        showFormModal: false
      }
    case actionTypes.CREATE_ERROR:
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
        backgroundPublishing: false,
      };
    case actionTypes.DELETE_SUCCESS:
    case actionTypes.UPDATE_ERROR:
    case actionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
      };

    case actionTypes.FETCH_INIT:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.limit,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
        loadingCommunityPost: action.payload.loadingScreen,
      };
    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loadingCommunityPost: false,
        rows: action.payload.rows,
        count: action.payload.count,
      };
    case actionTypes.FETCH_ERROR:
      return {
        ...state,
        loadingCommunityPost: false,
      };

    case actionTypes.DELETE_INIT:
      return {
        ...state,
        loadingCommunityPost: true,
      };

    case actionTypes.BACKGROUND_PUBLISHING:
      return {
        ...state,
        backgroundPublishing: true,
      };

    default:
      return state;
  }
};
