export default class UserStateService {
  constructor(simplifiedUsers) {
    this.users = simplifiedUsers;
  }

  findById(userId) {
    const user = this.users.find((u) => u.id === userId);
    if (user === undefined) {
      const placeholderUser = {
        id: userId,
        email: "unknownuser@email.com",
        roles: ["tenant"],
        name: "Unknown User",
      };
      return placeholderUser;
    }
    return user;
  }
}
