import i18next from "i18next";
import models from "modules/models";

export default class GroupStateService {
  constructor(group) {
    this.group = group;
  }
  get serviceOptions() {
    return [
      models.BOOKING,
      models.EXPENSE,
      models.POST,
      models.ISSUE,
      models.PACKAGE,
      models.KEYREQUEST,
    ];
  }

  isSubscriptionActive(subscription) {
    if (!subscription) {
      return false;
    }
    return subscription.isValid;
  }

  isServiceEnabled(serviceName) {
    if (!this.group) {
      return false;
    }
    return this.group.settings[`${serviceName}ServiceEnabled`];
  }

  setServiceState(enable, serviceName) {
    let updatedGroup = {
      ...this.group,
      settings: {
        ...this.group.settings,
        [`${serviceName}ServiceEnabled`]: enable,
      },
    };
    if (serviceName === models.BOOKING) {
      updatedGroup = {
        ...updatedGroup,
        settings: {
          ...updatedGroup.settings,
          [`serviceServiceEnabled`]: enable,
        },
      };
    }
    return updatedGroup;
  }

  createOptionDescriptionObjects() {
    const optionObjects = [];

    this.serviceOptions.forEach((o) => {
      optionObjects.push({
        title: i18next.t(`settings.options.${o}.title`),
        description: i18next.t(`settings.options.${o}.description`),
        enabled: this.group.settings[`${o}ServiceEnabled`],
        imageAsset: o,
        model: o,
      });
    });
    return optionObjects;
  }
}
