import config from "../../config"
import firebase from "firebase/app"
import "firebase/messaging";
import Message from "view/shared/message";
import UserApiService from "apiServices/userRxApiService";

export default class LayoutService {
    constructor(currentUser) {
        try {
            this.messaging = firebase.messaging();
        } catch (e) {
            this.messaging = null;
            console.log("Failed to initialize messaging");
        }
        this.currentUser = currentUser;
    }

    async enableMessaging() {
        if (!this.messaging) {
            return;
        }
        const token = await this.getToken();
        if (token) {
            await this.messaging.onMessage((payload) => {
                Message.info({ header: payload.notification.title, message: payload.notification.body });
            });
        }
    }

    async getToken() {
        try {
            const token = await this.messaging.getToken({ vapidKey: config.VAPID });
            if (token) {
                const userService = new UserApiService();
                userService.updateWebToken(token).subscribe();
            }
            return token;
        } catch (e) {
            console.log("Notification permission not granted");
            return null;
        }
    }

    async deleteToken() {
        await this.messaging.deleteToken();
    }
}