import { Grid, Typography, IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  conversationHeader: {
    minWidth: "100%",
    padding: "8px",
    backgroundColor: theme.palette.info.dark,
    color: "white",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
}));
export default function ChatDialogHeader(props) {
  const { onClose, title } = props;
  const classes = useStyles();
  return (
    <Grid
      item
      container
      className={classes.conversationHeader}
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={onClose}>
          <CloseIcon htmlColor="white" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
