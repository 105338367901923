import Roles from "security/roles";
const roles = Roles.values;

const editors = [roles.owner, roles.editor, roles.appManager];

const allusers = [roles.viewer].concat(editors);

class Permissions {
  static get values() {
    return {
      userDelete: {
        id: "userDelete",
        allowedRoles: [roles.owner, roles.userSecurityReviewer],
        allowedStorageFolders: ["user"],
      },
      userEdit: {
        id: "userEdit",
        allowedRoles: [roles.owner, roles.userSecurityReviewer, roles.editor],
        allowedStorageFolders: ["user"],
      },
      userCreate: {
        id: "userCreate",
        allowedRoles: [roles.owner, roles.userSecurityReviewer, roles.editor],
      },
      userRead: {
        id: "userRead",
        allowedRoles: [roles.userSecurityReviewer].concat(allusers),
      },
      settingsEdit: {
        id: "settingsEdit",
        allowedRoles: [roles.owner],
      },
      keyManagementCreate: {
        id: "keyManagementCreate",
        allowedRoles: [roles.keyManagementEditor, roles.keyManagementOwner].concat(editors),
        allowedStorageFolders: ["keyManagement"],
      },
      keyManagementEdit: {
        id: "keyManagementEdit",
        allowedRoles: [roles.keyManagementEditor].concat(editors),
        allowedStorageFolders: ["keyManagement"],
      },
      keyManagementDestroy: {
        id: "keyManagementDestroy",
        allowedRoles: [roles.keyManagementOwner, roles.owner],
        allowedStorageFolders: ["keyManagement"],
      },
      keyManagementRead: {
        id: "keyManagementRead",
        allowedRoles: [
          roles.keyManagementEditor,
          roles.keyManagementOwner,
          roles.keyManagementViewer,
        ].concat(allusers),
      },
      packageCreate: {
        id: "packageCreate",
        allowedRoles: [roles.packageEditor, roles.packageOwner].concat(editors),
        allowedStorageFolders: ["packageManager"],
      },
      packageEdit: {
        id: "packageEdit",
        allowedRoles: [roles.packageEditor].concat(editors),
        allowedStorageFolders: ["packageManager"],
      },
      packageDestroy: {
        id: "packageDestroy",
        allowedRoles: [roles.packageOwner, roles.owner],
        allowedStorageFolders: ["packageManager"],
      },
      packageRead: {
        id: "packageRead",
        allowedRoles: [
          roles.packageEditor,
          roles.packageViewer,
          roles.packageOwner,
        ].concat(allusers),
      },
      propertyCreate: {
        id: "propertyCreate",
        allowedRoles: [roles.propertyOwner, roles.propertyEditor].concat(
          editors
        ),
        allowedStorageFolders: ["property"],
      },
      propertyEdit: {
        id: "propertyEdit",
        allowedRoles: [roles.propertyEditor, roles.propertyOwner].concat(
          editors
        ),
        allowedStorageFolders: ["property"],
      },
      propertyDestroy: {
        id: "propertyDestroy",
        allowedRoles: [roles.propertyOwner, roles.owner],
        allowedStorageFolders: ["property"],
      },
      propertyRead: {
        id: "propertyRead",
        allowedRoles: [
          roles.propertyEditor,
          roles.propertyViewer,
          roles.packageOwner,
        ].concat(allusers),
      },
      issueCreate: {
        id: "issueCreate",
        allowedRoles: [roles.issueOwner, roles.issueEditor].concat(editors),
        allowedStorageFolders: ["issue"],
      },
      issueEdit: {
        id: "issueEdit",
        allowedRoles: [roles.issueEditor, roles.issueOwner].concat(editors),
        allowedStorageFolders: ["issue"],
      },
      issueDestroy: {
        id: "issueDestroy",
        allowedRoles: [roles.issueOwner, roles.owner],
        allowedStorageFolders: ["issue"],
      },
      issueRead: {
        id: "issueRead",
        allowedRoles: [
          roles.issueOwner,
          roles.issueEditor,
          roles.issueViewer,
          roles.packageOwner,
        ].concat(allusers),
      },
      jobCreate: {
        id: "jobCreate",
        allowedRoles: [roles.jobOwner, roles.jobEditor].concat(editors),
        allowedStorageFolders: ["job"],
      },
      jobEdit: {
        id: "jobEdit",
        allowedRoles: [roles.jobEditor, roles.jobOwner, roles.contractor].concat(editors),
        allowedStorageFolders: ["job"],
      },
      jobDestroy: {
        id: "jobDestroy",
        allowedRoles: [roles.jobOwner, roles.owner],
        allowedStorageFolders: ["job"],
      },
      jobRead: {
        id: "jobRead",
        allowedRoles: [
          roles.jobOwner,
          roles.jobEditor,
          roles.jobViewer,
          roles.packageOwner,
          roles.contractor,
        ].concat(allusers),
      },
      bookingCreate: {
        id: "bookingCreate",
        allowedRoles: [roles.bookingOwner, roles.bookingEditor].concat(editors),
        allowedStorageFolders: ["booking"],
      },
      bookingEdit: {
        id: "bookingEdit",
        allowedRoles: [roles.bookingEditor, roles.bookingOwner].concat(editors),
        allowedStorageFolders: ["booking"],
      },
      bookingDestroy: {
        id: "bookingDestroy",
        allowedRoles: [roles.bookingOwner, roles.owner],
        allowedStorageFolders: ["booking"],
      },
      bookingRead: {
        id: "bookingRead",
        allowedRoles: [
          roles.bookingEditor,
          roles.bookingViewer,
          roles.packageOwner,
        ].concat(allusers),
      },
      postCreate: {
        id: "postCreate",
        allowedRoles: [roles.postOwner, roles.postEditor].concat(editors),
        allowedStorageFolders: ["post"],
      },
      postEdit: {
        id: "postEdit",
        allowedRoles: [roles.postEditor, roles.postOwner].concat(editors),
        allowedStorageFolders: ["post"],
      },
      postDestroy: {
        id: "postDestroy",
        allowedRoles: [roles.postOwner, roles.owner],
        allowedStorageFolders: ["post"],
      },
      postRead: {
        id: "postRead",
        allowedRoles: [
          roles.postEditor,
          roles.postViewer,
          roles.packageOwner,
        ].concat(allusers),
      },
      serviceCreate: {
        id: "serviceCreate",
        allowedRoles: [roles.serviceOwner, roles.serviceEditor].concat(editors),
        allowedStorageFolders: ["service"],
      },
      serviceEdit: {
        id: "serviceEdit",
        allowedRoles: [roles.serviceEditor, roles.serviceOwner].concat(editors),
        allowedStorageFolders: ["service"],
      },
      serviceDestroy: {
        id: "serviceDestroy",
        allowedRoles: [roles.serviceOwner, roles.owner],
        allowedStorageFolders: ["service"],
      },
      serviceRead: {
        id: "serviceRead",
        allowedRoles: [
          roles.serviceEditor,
          roles.serviceViewer,
          roles.serviceOwner,
        ].concat(allusers),
      },
      communityCreate: {
        id: "communityCreate",
        allowedRoles: [roles.communityOwner, roles.communityEditor].concat(editors),
        allowedStorageFolders: ["community"],
      },
      communityEdit: {
        id: "communityEdit",
        allowedRoles: [roles.communityEditor, roles.communityOwner].concat(editors),
        allowedStorageFolders: ["community"],
      },
      communityDestroy: {
        id: "communityDestroy",
        allowedRoles: [roles.communityOwner, roles.owner],
        allowedStorageFolders: ["community"],
      },
      communityRead: {
        id: "communityRead",
        allowedRoles: [
          roles.communityEditor,
          roles.communityViewer,
          roles.communityOwner,
        ].concat(allusers),
      },

      expenseCreate: {
        id: "expenseCreate",
        allowedRoles: [roles.expenseOwner, roles.expenseEditor].concat(editors),
        allowedStorageFolders: ["expense"],
      },
      expenseEdit: {
        id: "expenseEdit",
        allowedRoles: [roles.expenseEditor, roles.expenseOwner].concat(editors),
        allowedStorageFolders: ["expense"],
      },
      expenseDestroy: {
        id: "expenseDestroy",
        allowedRoles: [roles.expenseOwner, roles.owner],
        allowedStorageFolders: ["expense"],
      },
      expenseRead: {
        id: "expenseRead",
        allowedRoles: [
          roles.expenseEditor,
          roles.expenseViewer,
          roles.packageOwner,
        ].concat(allusers),
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
