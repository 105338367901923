import authAxiosObs from "common/axios/authAxiosObservable";
import models from "modules/models";
import BaseRxApiService from "./baseRxApiService";

export default class UserRxApiService extends BaseRxApiService {
  constructor() {
    super(models.USER);
  }
  userExistByEmail(email) {
    return authAxiosObs.get(`/${models.USER}/exists/${email}`);
  }

  updateWebToken(token) {
    return authAxiosObs.put(`/${models.USER}/webtoken/${token}`);
  }

  ungroupUsers(userIds) {
    return authAxiosObs.put(`/${models.USER}/ungroup`, { data: userIds });
  }

  getAllSimplifiedUser() {
    let response = authAxiosObs.get(`/${models.USER}/simplified`);
    return response;
  }

  updateLastAllocated(id, propertyId) {
    return authAxiosObs.put(
      `/${models.USER}/${id}/allocatedproperty/${propertyId}`
    );
  }

  tenantUnallocated(id) {
    return authAxiosObs.put(`/${models.USER}/${id}/unallocatedproperty`);
  }
}
