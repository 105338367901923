const jsonPath = (langCode) => `/locales/${langCode}/translation.json`;

export const languages = {
  en: {
    id: "en",
    label: "English",
    flag: "/images/flags/32/round/uk.png",
    jsonUrl: jsonPath("en"),
    dateFns: undefined,
  },
  de: {
    id: "de",
    label: "German",
    flag: "/images/flags/32/round/germany.png",
    jsonUrl: jsonPath("de"),
    dateFns: undefined,
  },
  arabic: {
    id: "arabic",
    label: "UAE",
    flag: "/images/flags/32/round/uae.png",
    jsonUrl: jsonPath("arabic"),
    dateFns: undefined,
  },
};

export function getLanguage(code) {
  const profile = profileLookup(code);
  if (profile === undefined) {
    throw new Error(`Failed to find the language profile for ${code}.`);
  }
  return profile;
}

function profileExist(code) {
  return languages[code] !== undefined;
}

function profileLookup(code) {
  if (profileExist(code)) {
    return languages[code];
  } else {
    let variationRemoved = code.split("-")[0];
    if (variationRemoved !== undefined) {
      return profileLookup(variationRemoved);
    } else {
      return undefined;
    }
  }
}
