import Errors from "common/error/errors";

const prefix = "AUTH";

export const actionTypes = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,
  AUTH_INIT: `${prefix}_AUTH_INIT`,
  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,

  AUTH_SIGNOUT: `${prefix}_SIGNOUT`,

  EMAIL_VERIFICATION_START: `${prefix}_EMAIL_VERIFICATION_START`,
  EMAIL_VERIFICATION_COMPLETE: `${prefix}_EMAIL_VERIFICATION_COMPLETE`,
  EMAIL_VERIFICATION_FAIL: `${prefix}_EMAIL_VERIFICATION_FAIL`,

  REGISTER_USER: `${prefix}_REGISTER_USER`,
  REGISTER_USER_SUCCESS: `${prefix}_REGISTER_USER_SUCCESS`,
  REGISTER_USER_ERROR: `${prefix}_REGISTER_USER_ERROR`,
};

const actions = {
  startEmailVerification: (user) => ({
    type: actionTypes.EMAIL_VERIFICATION_START,
    payload: {
      user,
    },
  }),

  emailVerificationComplete: () => ({
    type: actionTypes.EMAIL_VERIFICATION_COMPLETE,
  }),

  emailVerificationFail: () => ({
    type: actionTypes.EMAIL_VERIFICATION_FAIL,
  }),

  authSignout: () => ({
    type: actionTypes.AUTH_SIGNOUT,
  }),

  doClearErrorMessage: () => ({
    type: actionTypes.ERROR_MESSAGE_CLEARED,
  }),

  authInit: (authenticationUser) => ({
    type: actionTypes.AUTH_INIT,
    payload: {
      authenticationUser,
    },
  }),

  authInitSuccess: (currentUser, authenticationUser) => ({
    type: actionTypes.AUTH_INIT_SUCCESS,
    payload: {
      currentUser,
      authenticationUser,
    },
  }),

  authInitError: (error) => ({
    type: actionTypes.AUTH_INIT_ERROR,
    payload: error,
  }),

  authStart: (email, password, rememberMe) => ({
    type: actionTypes.AUTH_START,
    payload: {
      email,
      password,
      rememberMe,
    },
  }),

  authSuccess: (currentUser, authenticationUser) => ({
    type: actionTypes.AUTH_SUCCESS,
    payload: {
      currentUser,
      authenticationUser,
    },
  }),

  authError: (error) => ({
    type: actionTypes.AUTH_ERROR,
    payload: Errors.selectMessage(error),
  }),

  registerUser: (email, password) => ({
    type: actionTypes.REGISTER_USER,
    payload: {
      email,
      password,
    },
  }),
  registerUserSuccess: () => ({
    type: actionTypes.REGISTER_USER_SUCCESS,
  }),
  registerUserError: () => ({
    type: actionTypes.REGISTER_USER_ERROR,
  }),
};

export default actions;
