import { map, catchError, switchMap, mergeMap, mapTo } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions, { actionTypes } from "./propertyActions";
import layoutActions from "../layout/layoutActions";
import PropertyRxApiService from "../../apiServices/propertyRxApiService";
import Message from "../../view/shared/message";
import i18next from "i18next";
import { of } from "rxjs";
import userAction from "modules/user/userActions";

const createProperyEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.CREATE_INIT),
    mergeMap((action) =>
      new PropertyRxApiService().create(action.payload.data).pipe(
        mergeMap((_) => {
          Message.success(
            i18next.t("entities.property.messages.createSuccess")
          );
          return of(
            actions.createSuccess(),
            layoutActions.dataOutdated() // will update local cache (which includes properties)
          );
        }),
        catchError((error) => {
          if (error.response.status === 409) {
            Message.warning(
              i18next.t("entities.property.messages.duplicateError")
            );
          } else if (error.response.status === 405) {
            Message.warning(
              i18next.t("entities.property.messages.exceededSubscriptionAllowance")
            );
          } else {
            Message.error(i18next.t("entities.property.messages.createError"));
          }
          return of(actions.createError());
        })
      )
    )
  );

const deletePropertyEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.DELETE_INIT),
    mergeMap((action) =>
      new PropertyRxApiService().delete(action.payload.id).pipe(
        mergeMap((_) => {
          Message.success(
            i18next.t("entities.property.messages.deleteSuccess")
          );
          return of(
            actions.deleteSuccess(),
            layoutActions.dataOutdated() // will update local cache (which includes properties)
          );
        }),
        catchError((error) => {
          Message.error(i18next.t("entities.property.messages.deleteError"));
          console.error(error);
          return of(actions.deleteError());
        })
      )
    )
  );

const getPropertiesEpic = (actions$, state$) =>
  actions$.pipe(
    ofType(actionTypes.FETCH_INIT),
    switchMap((action) => {
      return new PropertyRxApiService().get(state$.value.property.filter).pipe(
        mergeMap((response) => {
          if (action.payload.refreshUnfiltered === true) {
            return of(
              actions.setUnfilteredRows(response.data),
              actions.fetchSuccess(response.data, response.data.length)
            );
          } else {
            return of(
              actions.fetchSuccess(
                response.data,
                response.data.length,
                action.payload.page
              )
            );
          }
        }),
        catchError((error) => {
          console.error(error.response.data);
          Message.error(i18next.t("entities.property.messages.fetcherror"));
          return of(actions.fetchError());
        })
      );
    })
  );

const setFilterEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.SET_FILTER),
    map(() => actions.initializeFetch("number", "asc", 0, true))
  );

const updatePropertyEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.UPDATE_INIT),
    mergeMap((action) =>
      new PropertyRxApiService().update(action.payload.data).pipe(
        mapTo(action.payload.tenantsModified),
        mergeMap((modifiedTenants) => {
          Message.success(
            i18next.t("entities.property.messages.updatesuccess")
          );
          return of(
            layoutActions.dataOutdated(),
            actions.updateSuccess(),
            userAction.updateAllocation(modifiedTenants),
            actions.initializeFetch(
              "name",
              "asc",
              state$.value.property.page,
              false,
              true
            )
          );
        }),
        catchError((error) => {
          console.log(error);
          Message.error(i18next.t("entities.property.messages.updateerror"));
          return of(actions.updateError());
        })
      )
    )
  );

export default [
  getPropertiesEpic,
  setFilterEpic,
  updatePropertyEpic,
  createProperyEpic,
  deletePropertyEpic,
];
