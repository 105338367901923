import { combineEpics } from "redux-observable";
import authEpics from "./auth/authEpics";
import packageEpics from "./package/packageEpics";
import userEpics from "./user/userEpics";
import propertyEpics from "./property/propertyEpics";
import postEpics from "./post/postEpics";
import issueEpics from "./issue/issueEpics";
import serviceEpics from "./service/serviceEpics";
import bookingEpics from "./booking/bookingEpics";
import layoutEpics from "./layout/layoutEpics";
import groupEpics from "./group/groupEpics";
import expenseEpics from "./expense/expenseEpics";
import communityEpics from "./community/communityEpics";
import keyItemEpics from "./keyItem/keyItemEpics";
import keyRequestEpics from "./keyRequest/keyRequestEpics";
import keyRequestLogsEpics from "./keyRequestLogs/keyRequestLogsEpics";
import jobEpics from "./job/jobEpics";

export const rootEpic = combineEpics(
  ...authEpics,
  ...packageEpics,
  ...userEpics,
  ...propertyEpics,
  ...postEpics,
  ...issueEpics,
  ...serviceEpics,
  ...bookingEpics,
  ...layoutEpics,
  ...groupEpics,
  ...expenseEpics,
  ...communityEpics,
  ...keyItemEpics,
  ...keyRequestEpics,
  ...keyRequestLogsEpics,
  ...jobEpics
);
