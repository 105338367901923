import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Header from "view/layout/Header";
import Menu from "view/layout/Menu";
import { CircularProgress, makeStyles } from "@material-ui/core";
import propertyActions from "modules/property/propertyActions";
import userActions from "modules/user/userActions";
import layoutActions from "modules/layout/layoutActions";
import groupSelector from "modules/group/groupSelector";
import groupActions from "modules/group/groupActions";
import authSelector from "modules/auth/authSelectors";
import layoutSelector from "modules/layout/layoutSelectors";
import GroupStateService from "modules/group/groupStateService";
import NotSubscribed from "./NotSubscribed";
import SubscriptionUtility from "common/subscriptionUtility";
import models from "modules/models";
import { isMobile } from "react-device-detect";
import { Alert } from "@material-ui/lab";
import i18next from "i18next";
import LayoutService from "modules/layout/layoutService";


const useStyles = makeStyles((theme) => ({
  root: {
    color: "rgba(0, 0, 0, 0.65)",
    backgroundColor: "#f0f2f5",
    display: "flex",
    fontFamily: `'Roboto', sans-serif`,

    "& h1, h2, h3, h4, h5, h6": {
      color: "rgba(0, 0, 0, 0.85)",
    },
  },
  loading: {
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  container: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: "100vh",
    overflowX: "hidden",
  },
  overlay: {
    position: "fixed",
    display: "block",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "grey",
    opacity: 0.5,
    zIndex: 2,
  },
  loader: {
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  hidden: {
    display: "none"
  },
  toolbar: theme.mixins.toolbar,
}));



export default function Layout(props) {

  const classes = useStyles();
  // @ts-ignore
  const outdatedData = useSelector((state) => state.layout.outdatedData);
  const currentUser = useSelector(authSelector.selectCurrentUser);
  const requesting = useSelector(layoutSelector.selectRequest);
  const { loadingGroupInformation, group, subscription } = useSelector(
    groupSelector.selectGroupConfig
  );
  const dispatch = useDispatch();
  const groupStateService = new GroupStateService(group);
  const layoutService = new LayoutService(currentUser);

  useEffect(() => {
    if (group === null) {
      console.log("Getting group information");
      dispatch(groupActions.getGroup(currentUser.groupId));
    }
  }, []);

  useEffect(() => {
    if (outdatedData && subscription) {
      console.log("Data retrieved from server");

      layoutService.enableMessaging();

      const subscriptionUtil = new SubscriptionUtility(subscription);
      if (subscriptionUtil.isFeatureWithinSubscription(models.PROPERTY)) {
        dispatch(propertyActions.initializeFetch("name", "asc", 0, true, true));
      }

      dispatch(userActions.loadSimplifiedRows());

      dispatch(layoutActions.dataUpdated());
      return;
    }
  }, [outdatedData, subscription]);

  return (
    <Fragment>
      {loadingGroupInformation || group == null ? (
        <div className={classes.container}>
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        </div>
      ) : (group.isAdmin || groupStateService.isSubscriptionActive(subscription)) ? (
        <div className={classes.root}>
          <div className={requesting ? classes.overlay : classes.hidden}>
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          </div>
          <Header />
          {!isMobile &&
            <Menu url={props.match.url} />
          }

          <div className={classes.content}>
            <div className={classes.toolbar}></div>
            {isMobile && <Alert severity="warning">{i18next.t("home.messages.limitedView")}</Alert>}
            {props.children}
          </div>
        </div>
      ) : <NotSubscribed group={group.id} />}
    </Fragment>

  );
}
