import { actionTypes } from "./userActions";

const intitialState = {
  rows: [],
  simplifiedRows: { users: [] },
  loadingUsers: true,
  loadingSimplifiedUser: true,
  filter: {},
  showFormModal: false,
  initialFormValue: null,
  formEditMode: false,
  count: 0,
  order: "asc",
  orderBy: "firstName",
  page: 0,
  rowsPerPage: 5,
};

export default (state = intitialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_FORM:
      return {
        ...state,
        showFormModal: true,
        initialFormValue: action.payload.formValue,
      };
    case actionTypes.CLOSE_FORM:
      return {
        ...state,
        showFormModal: false,
        initialFormValue: null,
      };
    case actionTypes.CREATE_ERROR:
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
      };

    case actionTypes.UPDATE_ERROR:
    case actionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
      };

    case actionTypes.FETCH_INIT:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.limit,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
        loadingUsers: action.payload.loadingScreen,
      };
    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loadingUsers: false,
        loadingSimplifiedUser: false,
        rows: action.payload.rows,
        count: action.payload.count,
      };
    case actionTypes.FETCH_ERROR:
      return {
        ...state,
        loadingUsers: false,
      };
    case actionTypes.LOAD_SIMPLIFIED_ROWS_INIT:
      return {
        ...state,
        loadingSimplifiedUser: true,
      };
    case actionTypes.LOAD_SIMPLIFIED_ROWS_SUCCESS:
      return {
        ...state,
        simplifiedRows: action.payload.rows,
        loadingSimplifiedUser: false,
      };
    case actionTypes.LOAD_SIMPLIFIED_ROWS_ERROR:
      return {
        ...state,
      };
    case actionTypes.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    case actionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };

    case actionTypes.SET_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };

    case actionTypes.SET_ORDERBY:
      return {
        ...state,
        orderBy: action.payload.orderBy,
      };

    case actionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
      };

    case actionTypes.DELETE_INIT:
      return {
        ...state,
        loadingUsers: true,
      };

    case actionTypes.CLEAR_CACHE: {
      return intitialState;
    }

    default:
      return state;
  }
};
