const prefix = "KEYREQUEST";

export const actionTypes = {
  FETCH_INIT: `${prefix}_FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  CREATE_INIT: `${prefix}_CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,
  CANCEL_REQUEST_SUCCESS: `${prefix}_CANCEL_REQUEST_SUCCESS`,
  CANCEL_REQUEST_INIT: `${prefix}_CANCEL_REQUEST_INIT`,
  CONFIRM_COLLECTION_SUCCESS: `${prefix}CONFIRM_COLLECTION_SUCCESS`,
  CONFIRM_COLLECTION_INIT: `${prefix}CONFIRM_COLLECTION_INIT`,
  CONFIRM_RETURN_SUCCESS: `${prefix}_CONFIRM_RETURN_SUCCESS`,
  CONFIRM_RETURN_INIT: `${prefix}_CONFIRM_RETURN_INIT`,
  CONFIRM_MISSING_RETURN_SUCCESS: `${prefix}_CONFIRM_MISSING_RETURN_SUCCESS`,
  CONFIRM_MISSING_RETURN_INIT: `${prefix}_CONFIRM_MISSING_RETURN_INIT`,
  CONFIRM_NOT_RETURNED_SUCCESS: `${prefix}_CONFIRM_NOT_RETURNED _SUCCESS`,
  CONFIRM_NOT_RETURNED_INIT: `${prefix}_CONFIRM_NOT_RETURNED _INIT`,
  UPDATE_INIT: `${prefix}_UPDATE_INIT`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
  SHOW_FORM: `${prefix}_SHOW_FORM`,
  CLOSE_FORM: `${prefix}_CLOSE_FORM`,
  SHOW_COLLECTION_DIALOG: `${prefix}_SHOW_COLLECTION_DIALOG`,
  CLOSE_COLLECTION_DIALOG: `${prefix}_CLOSE_COLLECTION_DIALOG`,
  SET_FILTER: `${prefix}_SET_FILTER`,
  SET_PAGE: `${prefix}_SET_PAGE`,
  SET_ORDER: `${prefix}_SET_ORDER`,
  SET_ORDERBY: `${prefix}_SET_ORDERBY`,
  SET_ROWS_PER_PAGE: `${prefix}_SET_ROWS_PER_PAGE`,
};

const actions = {
  openRequestForm: (formValue = null) => ({
    type: actionTypes.SHOW_FORM,
    payload: {
      formValue,
    },
  }),
  closeRequestForm: () => ({
    type: actionTypes.CLOSE_FORM,
  }),

  createRequest: (data) => ({
    type: actionTypes.CREATE_INIT,
    payload: { data },
  }),

  createSuccess: () => ({
    type: actionTypes.CREATE_SUCCESS,
  }),

  createError: () => ({
    type: actionTypes.CREATE_ERROR,
  }),

  updateRequest: (data) => ({
    type: actionTypes.UPDATE_INIT,
    payload: {
      data,
    },
  }),

  updateSuccess: () => ({
    type: actionTypes.UPDATE_SUCCESS,
  }),


  cancelRequest: (id) => ({
    type: actionTypes.CANCEL_REQUEST_INIT,
    payload: {
      id,
    },
  }),

  cancelRequestSuccess: () => ({
    type: actionTypes.CANCEL_REQUEST_SUCCESS,
  }),

  confirmCollection: (requestId, collectorId, note) => ({
    type: actionTypes.CONFIRM_COLLECTION_INIT,
    payload: {
      requestId, collectorId, note
    },
  }),

  confirmCollectionSuccess: () => ({
    type: actionTypes.CONFIRM_COLLECTION_SUCCESS,
  }),

  confirmReturn: (requestId, collectorId, note) => ({
    type: actionTypes.CONFIRM_RETURN_INIT,
    payload: {
      requestId,
      collectorId,
      note
    },
  }),

  confirmReturnSuccess: () => ({
    type: actionTypes.CONFIRM_RETURN_SUCCESS,
  }),

  confirmMissingReturn: (requestId, collectorId, note) => ({
    type: actionTypes.CONFIRM_MISSING_RETURN_INIT,
    payload: {
      requestId,
      collectorId,
      note
    },
  }),

  confirmMissingReturnSuccess: () => ({
    type: actionTypes.CONFIRM_MISSING_RETURN_SUCCESS,
  }),

  confirmNotReturned: (requestId) => ({
    type: actionTypes.CONFIRM_NOT_RETURNED_INIT,
    payload: {
      requestId
    },
  }),

  confirmNotReturnedSuccess: () => ({
    type: actionTypes.CONFIRM_NOT_RETURNED_SUCCESS,
  }),

  updateError: () => ({
    type: actionTypes.UPDATE_ERROR,
  }),

  showCollectionDialog: () => ({
    type: actionTypes.SHOW_COLLECTION_DIALOG,
  }),

  closeCollectionDialog: () => ({
    type: actionTypes.CLOSE_COLLECTION_DIALOG,
  }),

  initializeFetch: (
    limit,
    orderBy,
    order,
    page,
    offset = null,
    loadingScreen = true
  ) => ({
    type: actionTypes.FETCH_INIT,
    payload: {
      page,
      limit,
      offset,
      orderBy,
      order,
      loadingScreen,
    },
  }),

  fetchSuccess: (rows, count) => ({
    type: actionTypes.FETCH_SUCCESS,
    payload: {
      rows,
      count,
    },
  }),

  fetchError: () => ({
    type: actionTypes.FETCH_ERROR,
  }),

  deleteRequests: (requestIds) => ({
    type: actionTypes.DELETE_INIT,
    payload: {
      requestIds,
    },
  }),
  deleteRequestSuccess: () => ({
    type: actionTypes.DELETE_SUCCESS,
  }),

  setFilter: (filter) => ({
    type: actionTypes.SET_FILTER,
    payload: {
      filter,
    },
  }),
  setOrder: (order) => ({
    type: actionTypes.SET_ORDER,
    payload: {
      order,
    },
  }),
  setOrderBy: (orderBy) => ({
    type: actionTypes.SET_ORDERBY,
    payload: {
      orderBy,
    },
  }),
  setPage: (page) => ({
    type: actionTypes.SET_PAGE,
    payload: {
      page,
    },
  }),
  setRowsPerPage: (rowsPerPage) => ({
    type: actionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }),
};

export default actions;
