import ChatApiService from "apiServices/chatApiService";
import ChatService from "modules/chat/chatService";
import { ofType } from "redux-observable";
import { from } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import actions, { actionTypes } from "./layoutActions";

const createChatEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CREATE_OR_OPEN_CHAT),
    mergeMap((action) =>
      from(new ChatApiService().createOrRetrieveChat(action.payload)).pipe(
        map((chat) => {
          const updatedChat = ChatService.populateChatObject(
            chat,
            state$.value.auth.currentUser,
            state$.value.property.unfilteredRows,
            state$.value.user.simplifiedRows
          );

          return actions.openChat(updatedChat);
        })
      )
    )
  );

export default [createChatEpic];
