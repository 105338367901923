import AuthService from "modules/auth/authService";
import { map, mergeMap, catchError, tap, delay, mapTo } from "rxjs/operators";
import { ofType } from "redux-observable";
import actions from "./authActions";
import { actionTypes } from "./authActions";
import { of, from } from "rxjs";
import Message from "../../view/shared/message";
import i18next from "i18next";
import AuthApiService from "apiServices/authApiService";
import AppApiService from "apiServices/appApiService";
import userActions from "modules/user/userActions";
import propertyActions from "modules/property/propertyActions";
import layoutActions from "modules/layout/layoutActions";

const authApiService = new AuthApiService();

const signinWithEmailAndPasswordEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.AUTH_START),
    mergeMap((action) =>
      from(
        AuthService.signinWithEmailAndPassword(
          action.payload.email,
          action.payload.password,
          action.payload.rememberMe
        )
      ).pipe(
        map((credResponse) =>
          actions.authSuccess(
            credResponse.currentUser,
            credResponse.authenticationUser
          )
        ),
        catchError((err) => of(actions.authError(err)))
      )
    )
  );

const doSignInFromAuthChangeEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.AUTH_INIT),
    mergeMap((action) =>
      from(
        AuthService.getCurrentAuthenticatedUser(
          action.payload.authenticationUser
        )
      ).pipe(
        mergeMap((authResponse) => {
          if (authResponse.authenticationUser) {
            return from(AppApiService.checkAppState()).pipe(
              tap((isValid) =>
                console.log(
                  "Successfully checked data state and result is => " + isValid
                )
              ),
              mapTo(authResponse)
            );
          }
          return of(authResponse);
        }),
        map(({ authenticationUser, currentUser }) =>
          actions.authInitSuccess(currentUser, authenticationUser)
        ),
        catchError((e) => {
          console.log(e);
          return of(actions.authInitError(e));
        })
      )
    )
  );

const sendEmailVerificationEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.EMAIL_VERIFICATION_START),
    mergeMap((action) =>
      from(AuthService.sendEmailVerification(action.payload.user)).pipe(
        tap(() =>
          Message.success(
            i18next.t("entities.auth.emailUnverified.emailSentSuccess")
          )
        ),
        delay(60000),
        map(() => {
          return actions.emailVerificationComplete();
        })
      )
    ),
    catchError((error) => {
      Message.error(i18next.t("entities.auth.emailUnverified.emailSentFail"));
      return of(actions.authError(error));
    })
  );

const doSignoutEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.AUTH_SIGNOUT),
    mergeMap(() => from(AuthService.signout())),
    mergeMap(() =>
      of(
        actions.authSuccess(null, null),
        userActions.clearCache(),
        propertyActions.clearCache(),
        layoutActions.dataOutdated()
      )
    )
  );

const registerUserEpics = (action$) =>
  action$.pipe(
    ofType(actionTypes.REGISTER_USER),
    mergeMap((action) => {
      return from(
        AuthService.registerWithEmailAndPassword(
          action.payload.email,
          action.payload.password
        )
      ).pipe(
        mergeMap((authUser) =>
          from(authApiService.fetchMe()).pipe(

            mergeMap((currentUser) => {
              currentUser.emailVerified = authUser.emailVerified;
              return of(actions.authSuccess(currentUser, authUser));
            })
          ),
        ),
        catchError((error) => {
          if (error.code === "auth/email-already-in-use") {
            Message.warning(
              i18next.t("entities.auth.emailUnverified.userAlreadyRegistered")
            );
          } else if (error.code === "auth/weak-password") {
            Message.warning(
              i18next.t("entities.auth.emailUnverified.weakPassword")
            );
          } else {
            Message.error(
              i18next.t("entities.auth.emailUnverified.registerationError")
            );
          }
          return of(actions.authError("User already exists"));
        })
      );
    })
  );

export default [
  doSignoutEpic,
  sendEmailVerificationEpic,
  doSignInFromAuthChangeEpic,
  signinWithEmailAndPasswordEpic,
  registerUserEpics,
];
