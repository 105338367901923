import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  Badge,
  Typography,
} from "@material-ui/core";
import UserMenu from "view/layout/UserMenu";
import i18next from "i18next";
import ChatDrawer from "./ChatDrawer";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ChatApiService from "apiServices/chatApiService";
import authSelector from "modules/auth/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import layoutSelectors from "modules/layout/layoutSelectors";
import layoutActions from "modules/layout/layoutActions";
import MenuIcon from "@material-ui/icons/Menu";
import { isMobile } from "react-device-detect";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    height: "50px",
    marginRight: "15px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  grow: {
    flex: "1 1 auto",
  },
}));

export default function Header() {
  const [openChat, setOpenChat] = useState(false);
  const [chats, setChats] = useState([]);
  const currentUser = useSelector(authSelector.selectCurrentUser);
  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);
  const dispatch = useDispatch();

  const settings = JSON.parse(sessionStorage.getItem("settings"))

  const chatService = new ChatApiService();

  const handleDrawerOpen = () => {
    dispatch(layoutActions.doShowMenu());
  };

  const handleChatDrawerOpen = () => {
    setOpenChat(true);
  };

  const handleChatDrawerClose = () => {
    setOpenChat(false);
  };

  useEffect(() => {
    let chatStream;
    chatService.allChatStream().then((stream) => {
      chatStream = stream.onSnapshot((doc) => {
        const temp = doc.docs.map((d) => d.data());
        setChats(temp);
      });
    });
    return () => {
      if (chatStream) {
        chatStream();
      }
    };
  }, []);

  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: menuVisible,
      })}
      position="fixed"
    >
      <Toolbar>
        {!isMobile && <IconButton
          color="inherit"
          aria-label="open menu drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: menuVisible,
          })}
        >
          <MenuIcon />
        </IconButton>}
        <div>
          <img
            className={classes.logo}
            alt="imagelogo"
            src={`/images/logo/${settings.theme}/whitelogo.png`}
          />
        </div>

        <Typography variant="h5" style={{ color: "white" }}>
          {i18next.t("app.title")}
        </Typography>

        <div className={classes.grow} />
        {/* 
          <I18nSelect /> */}
        <UserMenu />
        {!isMobile && <IconButton
          color="inherit"
          aria-label="open chat drawer"
          edge="end"
          onClick={handleChatDrawerOpen}
        >
          <Badge
            badgeContent={
              chats.filter((c) => !c.readBy.some((id) => id === currentUser.id))
                .length
            }
            color="secondary"
          >
            <QuestionAnswerIcon />
          </Badge>
        </IconButton>}
      </Toolbar>
      <ChatDrawer
        open={openChat}
        handleDrawerClose={handleChatDrawerClose}
        chats={chats}
      />
    </AppBar>
  );
}
