import { actionTypes } from "./keyItemActions";

const intitialState = {
  rows: [],
  loadingKeys: false,
  filter: {},
  showFormModal: false,
  initialFormValue: null,
  count: 0,
  order: "desc",
  orderBy: "createdAt",
  page: 0,
  rowsPerPage: 5,
};

export default (state = intitialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_FORM:
      return {
        ...state,
        showFormModal: true,
        initialFormValue: action.payload.formValue,
      };

    case actionTypes.CLOSE_FORM:
      return {
        ...state,
        showFormModal: false,
        initialFormValue: null,
      };

    case actionTypes.CREATE_ERROR:
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
      };

    case actionTypes.FETCH_INIT:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.limit,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
        loadingKeys: action.payload.loadingScreen,
      };
    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loadingKeys: false,
        rows: action.payload.rows,
        count: action.payload.count,
      };
    case actionTypes.FETCH_ERROR:
      return {
        ...state,
        loadingKeys: false,
      };
    case actionTypes.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    case actionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };

    case actionTypes.SET_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };

    case actionTypes.SET_ORDERBY:
      return {
        ...state,
        orderBy: action.payload.orderBy,
      };

    case actionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
      };

    case actionTypes.UPDATE_ERROR:
    case actionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
      };

    default:
      return state;
  }
};
