const prefix = "LAYOUT";

export const actionTypes = {

  REQUESTING_START: `${prefix}_EQUESTING_START`,
  REQUESTING_END: `${prefix}_REQUESTING_END`,
  MENU_TOGGLE: `${prefix}_MENU_TOGGLE`,
  MENU_HIDE: `${prefix}_MENU_HIDE`,
  MENU_SHOW: `${prefix}_MENU_SHOW`,
  DATA_UPDATED: `${prefix}_DATA_UPDATED`,
  DATA_OUTDATED: `${prefix}_DATA_OUTDATED`,
  OPEN_CHAT: `${prefix}_OPEN_CHAT`,
  CLOSE_CHAT: `${prefix}_CLOSE_CHAT`,
  CREATE_OR_OPEN_CHAT: `${prefix}_CREATE_OR_OPEN_CHAT`,
  CREATE_OR_OPEN_CHAT_SUCCESS: `${prefix}_CREATE_OR_OPEN_CHAT_SUCCESS`,
  CREATE_OR_OPEN_CHAT_ERROR: `${prefix}_CREATE_OR_OPEN_CHAT_ERROR`,
};
const actions = {

  requestStart: () => ({
    type: actionTypes.REQUESTING_START
  }),

  requestEnd: () => ({
    type: actionTypes.REQUESTING_END
  }),

  dataUpdated: () => ({
    type: actionTypes.DATA_UPDATED,
  }),
  /**
   * Set the data to outdated so it would retrieve from server on layout render
   */
  dataOutdated: () => ({
    type: actionTypes.DATA_OUTDATED,
  }),

  doToggleMenu: () => {
    return {
      type: actionTypes.MENU_TOGGLE,
    };
  },

  doShowMenu: () => {
    return {
      type: actionTypes.MENU_SHOW,
    };
  },

  doHideMenu: () => {
    return {
      type: actionTypes.MENU_HIDE,
    };
  },

  createOrOpenChat: (chatItem) => {
    return {
      type: actionTypes.CREATE_OR_OPEN_CHAT,
      payload: chatItem,
    };
  },

  createOrOpenChatSuccess: () => {
    return {
      type: actionTypes.CREATE_OR_OPEN_CHAT_SUCCESS,
    };
  },

  createOrOpenChatError: () => {
    return {
      type: actionTypes.CREATE_OR_OPEN_CHAT_ERROR,
    };
  },

  openChat: (chatItem) => {
    return {
      type: actionTypes.OPEN_CHAT,
      payload: chatItem,
    };
  },

  closeChat: (chatId) => {
    return {
      type: actionTypes.CLOSE_CHAT,
      payload: chatId,
    };
  },
};

export default actions;
