import Permissions from "security/permissions";
import i18next from "i18next";
import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import ApartmentIcon from "@material-ui/icons/Apartment";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import HealingIcon from "@material-ui/icons/Healing";
import WbIncandescentOutlinedIcon from "@material-ui/icons/WbIncandescentOutlined";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import models from "modules/models";
import AmpStoriesIcon from "@material-ui/icons/AmpStories";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from '@material-ui/icons/Info';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: "/",
    icon: <AmpStoriesIcon />,
    label: () => i18next.t("home.menu"),
    menu: {
      exact: true,
    },
    loader: () => import("view/home/HomePage"),
    permissionRequired: null,
    exact: true,
    alwaysEnabled: true,
  },
  {
    path: "/users",
    model: models.USER,
    loader: () => import("view/user/UserPage"),
    permissionRequired: permissions.userRead,
    exact: true,
    icon: <PeopleIcon />,
    label: () => i18next.t("entities.user.menu"),
    menu: true,
    alwaysEnabled: true,
  },
  {
    path: "/properties",
    model: models.PROPERTY,
    loader: () => import("view/property/PropertyPage"),
    permissionRequired: permissions.propertyRead,
    exact: true,
    icon: <ApartmentIcon />,
    label: () => i18next.t("entities.property.menu"),
    menu: true,
    alwaysEnabled: true,
  },
  {
    path: "/packages",
    model: models.PACKAGE,
    loader: () => import("view/package/PackagePage"),
    permissionRequired: permissions.packageRead,
    exact: true,
    icon: <ViewQuiltIcon />,
    label: () => i18next.t("entities.package.menu"),
    menu: true,
    alwaysEnabled: false,
  },
  {
    path: "/posts",
    model: models.POST,
    loader: () => import("view/post/PostPage"),
    permissionRequired: permissions.postRead,
    exact: true,
    icon: <DynamicFeedIcon />,
    label: () => i18next.t("entities.post.menu"),
    menu: true,
    alwaysEnabled: false,
  },
  {
    path: "/community",
    model: models.COMMUNITY,
    loader: () => import("view/community/CommunityPage"),
    permissionRequired: permissions.communityRead,
    exact: true,
    icon: <InfoIcon />,
    label: () => i18next.t("entities.community.menu"),
    menu: true,
    alwaysEnabled: true,
  },
  {
    path: "/issues",
    model: models.ISSUE,
    loader: () => import("view/issue/IssuePage"),
    permissionRequired: permissions.issueRead,
    exact: true,
    icon: <HealingIcon />,
    label: () => i18next.t("entities.issue.menu"),
    menu: true,
    alwaysEnabled: false,
  },
  {
    path: "/service",
    model: models.SERVICE,
    loader: () => import("view/service/ServicePage"),
    permissionRequired: permissions.serviceRead,
    exact: true,
    icon: <WbIncandescentOutlinedIcon />,
    label: () => i18next.t("entities.service.menu"),
    menu: true,
    alwaysEnabled: false,
  },
  {
    path: "/job",
    model: models.JOB,
    loader: () => import("view/job/JobPage"),
    permissionRequired: permissions.jobRead,
    exact: true,
    icon: <WbIncandescentOutlinedIcon />,
    label: () => i18next.t("entities.job.menu"),
    menu: true,
    alwaysEnabled: false,
  },
  {
    path: "/booking",
    model: models.BOOKING,
    loader: () => import("view/booking/BookingPage"),
    permissionRequired: permissions.bookingRead,
    exact: true,
    icon: <EventAvailableIcon />,
    label: () => i18next.t("entities.booking.menu"),
    menu: true,
    alwaysEnabled: false,
  },
  {
    path: "/expense",
    model: models.EXPENSE,
    loader: () => import("view/expense/ExpensePage"),
    permissionRequired: permissions.expenseRead,
    exact: true,
    icon: <AccountBalanceWalletIcon />,
    label: () => i18next.t("entities.expense.menu"),
    menu: true,
    alwaysEnabled: false,
  },
  {
    path: "/keymanagement",
    model: models.KEYREQUEST,
    loader: () => import("view/keyManagement/KeyManagementPage"),
    permissionRequired: permissions.keyManagementRead,
    exact: true,
    icon: <VpnKeyIcon />,
    label: () => i18next.t("entities.keyManagement.menu"),
    menu: true,
    alwaysEnabled: false,
  },
  {
    path: "/settings",
    loader: () => import("view/settings/SettingsPage"),
    permissionRequired: permissions.settingsEdit,
    menu: {
      exact: true,
    },
    icon: <SettingsIcon />,
    label: () => i18next.t("entities.setting.menu"),
    alwaysEnabled: true,
  },
];

const publicRoutes = [
  {
    path: "/auth/signin",
    loader: () => import("view/auth/SigninPage"),
  },
  {
    path: "/auth/signup",
    loader: () => import("view/auth/SignupPage"),
  },
  {
    path: "/auth/creategroup",
    loader: () => import("view/auth/CreateGroupPage")
  },
  {
    path: "/auth/forgot-password",
    loader: () => import("view/auth/ForgotPasswordPage"),
  },
];

const emptyPermissionsRoutes = [
  {
    path: "/auth/empty-permissions",
    loader: () => import("view/auth/EmptyPermissionsPage"),
  },
];

const emailUnverifiedRoutes = [
  {
    path: "/auth/email-unverified",
    loader: () => import("view/auth/EmailUnverifiedPage"),
  },
];
const tenantRoute = [
  {
    path: "/welcome",
    loader: () => import("view/auth/AuthenticatedTenantPage"),
  },
];

const simpleRoutes = [
  {
    path: "/403",
    loader: () => import("view/shared/errors/Error403Page"),
  },
  {
    path: "/500",
    loader: () => import("view/shared/errors/Error500Page"),
  },
  {
    path: "**",
    loader: () => import("view/shared/errors/Error404Page"),
  },
];

export default {
  tenantRoute,
  privateRoutes,
  publicRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
