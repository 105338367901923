import { actionTypes } from "./serviceActions";

const intitialState = {
  rows: [],
  loadingService: false,
  showFormModal: false,
  initialFormValue: null,
};

const maintenanceService = {
  id: "maintenancebookingservice",
  description: "Maintenance booking",
  detail: "Maintenance Booking",
  estimate: "instant",
  promptTimeMessage: "Booking Time Window",
  hasCalendar: true,
  hasCost: false,
  title: "Maintenance",
  hideFromTenantSelection: true,
  reschedulingDescription:
    "Cancelling booking will notify all relevant users",
  imageAsset: "booking",
}

export default (state = intitialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_FORM:
      return {
        ...state,
        showFormModal: true,
        initialFormValue: action.payload.formValue,
      };
    case actionTypes.CLOSE_FORM:
      return {
        ...state,
        showFormModal: false,
        initialFormValue: null,
      };
    case actionTypes.CREATE_ERROR:
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
      };
    case actionTypes.DELETE_SUCCESS:
    case actionTypes.UPDATE_ERROR:
    case actionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        showFormModal: false,
      };

    case actionTypes.FETCH_INIT:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.limit,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
        loadingService: action.payload.loadingScreen,
      };
    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loadingService: false,
        rows: [...action.payload.rows, maintenanceService],
        count: action.payload.count,
      };
    case actionTypes.FETCH_ERROR:
      return {
        ...state,
        loadingService: false,
      };

    case actionTypes.DELETE_INIT:
      return {
        ...state,
        loadingService: true,
      };

    default:
      return state;
  }
};
