import {
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment";
import React from "react";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PersonIcon from "@material-ui/icons/Person";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

const useStyles = makeStyles((theme) => ({
  unread: {
    color: theme.palette.info.main,
  },
  chatItem: {
    backgroundColor: "white",
    minHeight: "70px",
  },
}));

export default function ChatItem(props) {
  const { chatItemSelected, populatedChat, chat, read, isUserChat } = props;
  const classes = useStyles();

  return (
    <ListItem
      onClick={() => chatItemSelected(populatedChat)}
      button
      className={classes.chatItem}
      key={chat.id}
    >
      <ListItemIcon>
        {read ? (
          <DoneAllIcon />
        ) : (
          <FiberManualRecordIcon className={classes.unread} />
        )}
        {"  "}
        {isUserChat ? <PersonIcon /> : <HomeWorkIcon />}
      </ListItemIcon>

      <ListItemText primary={populatedChat.title} />
      <Typography variant="caption">
        {moment(new Date(chat.updatedAt.seconds * 1000)).calendar()}
      </Typography>
    </ListItem>
  );
}
