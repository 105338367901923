export const invitationTypes = Object.freeze({
  USERREQUEST: 0,
  GROUPINVITE: 1,
});

export const bookingStatus = Object.freeze({
  PENDING: 0, // Awaiting update
  READY: 1, // Set for collection
  BOOKED: 2, // Booked but date not yet passed
  COMPLETED: 3, // Set manually when request is fullfilled
  CANCELLED: 4, // When cancelled appointment owner
});

export const bookingActions = Object.freeze({
  CANCEL: 0,
  REJECT: 1,
  APPROVE: 2,
  READY: 3,
  COMPLETE: 4,
});

export const propertyType = Object.freeze({
  FLAT: 0,
  HOUSE: 1,
});

export const postType = Object.freeze({
  PAGE: 0,
  IMAGE: 1,
  TEXT: 2,
});

export const postFilterType = Object.freeze({
  QUESTION: 0,
  ANNOUNCEMENT: 1,
  GENERAL: 2,
  RECOMMENDATION: 3
});

export const subscriptionTiers = Object.freeze({
  PERSONAL: 0,
  BUSINESS: 1,
  COMMUNITY: 2
});


export const weekdays = Object.freeze({
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
});

export const chatType = Object.freeze({
  USER: 0,
  PROPERTY: 1,
  GROUP: 2,
});

export const currency = Object.freeze({
  GBP: 0,
  USD: 1,
  EURO: 2,
});

export const propertyChatTypes = Object.freeze({
  ALL: 0,
  TENANT: 1,
  LANDLORD: 2,
  BROADCAST: 3,
  OTHER: 4,
})

export const expenseCategory = Object.freeze({
  PRODUCT: 0,
  MAINTENANCE: 1,
  BILL: 2,
  INSURANCE: 3,
  SERVICE: 4,
  AGENTFEE: 5,
  LEGALFEE: 6,
  ADVERTISMENT: 7,
  OTHER: 8,
});

export const packageType = Object.freeze({
  LETTER: 0,
  PARCEL: 1,
  HEAVYPARCEL: 2,
  URGENTPARCEL: 3,
});

export const graphType = Object.freeze({
  EXPENSECATEGORY: 0,
  EXPENSEPROPERTY: 1,
  ISSUEOVERVIEW: 2,
  ISSUERESOLUTION: 3
});

export const keyTypes = Object.freeze({
  SET: 0,
  FOB: 1,
  REMOTECONTROL: 2
})

export const keyRequestStatus = Object.freeze({
  AWAITINGCOLLECTION: 0,
  COLLECTED: 1,
  RETURNED: 2,
  RETURNEDMISSINGKEYS: 3,
  NOTRETURNED: 4,
  CANCELLED: 5,
})

export const ongoingRequestOptions = Object.freeze({
  DAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 6
})

export const keyRequestType = Object.freeze({
  ONETIME: 0,
  ONGOING: 1,
})

export const keyExchageType = Object.freeze({
  COLLECT: 0,
  RETURN: 1,
  MISSINGKEYS: 2,
  NOTRETURNED: 3,
})

export const keyStatus = Object.freeze({
  AVAILABLE: 0,
  UNAVAILABLE: 1,
  MISSING: 2
})