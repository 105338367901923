import { createSelector } from "reselect";
import { propertyTypeAsString } from "view/formModels/helpers/propertyHelper";
import * as _ from "lodash";

const root = (state) => state.property;

const selectPageConfig = createSelector(root, (propertyState) => ({
  filter: propertyState.filter,
  page: propertyState.page,
}));

const selectRows = createSelector(root, (propertyState) => ({
  rows: propertyState.rows,
  unfilteredRows: propertyState.unfilteredRows,
  count: propertyState.count,
  tenants: propertyState.tenants,
  loadingUnfilteredRows: propertyState.loadingUnfilteredRows,
}));

const selectAvailableProperties = createSelector(root, (propertyState) => ({
  rows: propertyState.rows.filter(p => !p.disabled),
  unfilteredRows: propertyState.unfilteredRows,
  count: propertyState.count,
  tenants: propertyState.tenants,
  loadingUnfilteredRows: propertyState.loadingUnfilteredRows,
}));


const propertyOptions = createSelector(root, (propertyState) => {
  return _.orderBy(
    propertyState.unfilteredRows
      .filter(p => !p.disabled)
      .map((property) => ({
        id: property.id,
        value: `${property.id}`,
        label: `${property.number} ${property.name} (${propertyTypeAsString(
          property.type
        )})`,
        number: property.number,
        name: property.name,
      })),
    ["name", "number"],
    ["asc", "asc"]
  );
});

const isLoadingRows = createSelector(
  root,
  (propertyState) => propertyState.loadingProperties
);

const selectFormState = createSelector(root, (propertyState) => ({
  showFormModal: propertyState.showFormModal,
  initialFormValue: propertyState.initialFormValue,
}));

const selectAllTenants = createSelector(
  root,
  (propertyState) => propertyState.tenants
);

export default {
  selectAvailableProperties,
  selectPageConfig,
  selectRows,
  isLoadingRows,
  selectFormState,
  selectAllTenants,
  propertyOptions
};
