import FileManager from "@quarkmodule/firebasewrapper/modules/firebaseFileManager";
import { v4 as uuidv4 } from "uuid";
import StringUtility from "./stringUtility";
export default class UploadUtility {
  static extractExtensionFromFileName(filename) {
    if (!filename) {
      return null;
    }

    const regex = /(?:\.([^.]+))?$/;
    return regex.exec(filename)[1];
  }

  static createFullPathWithUuid(base, filename) {
    return `${base}/${uuidv4()}.${this.extractExtensionFromFileName(filename)}`;
  }
  /**
   * add the resized folder directory to the images
   * @param {*} url 
   * @param {*} filename 
   * @returns 
   */
  static appendResizedFolder(url, filename) {
    var appendToken = "resized%2F";
    var insertionIndex = url.indexOf(filename);
    var newUrl = StringUtility.insertAtIndex(url, insertionIndex, appendToken);
    return newUrl;
  }

  static appendThumbnailFolder(url, filename) {
    var appendToken = "thumbnail%2F";
    var insertionIndex = url.indexOf(filename);
    var newUrl = StringUtility.insertAtIndex(url, insertionIndex, appendToken);
    return newUrl;
  }

  static async uploadHtmlContent(htmlContent, path) {
    const htmlUploadPath = UploadUtility.createFullPathWithUuid(path,
      `${uuidv4()}.html`
    );
    const htmlContentBlob = new Blob([htmlContent], { type: "text/html" });
    const uploadHtmlResponse = await FileManager.upload(htmlUploadPath, htmlContentBlob);
    return uploadHtmlResponse;
  }

  static async uploadPostImage(file, path) {
    const imageUploadPath = UploadUtility.createFullPathWithUuid(
      path,
      file.name
    );
    const response = await FileManager.upload(imageUploadPath, file);
    return response;
  }

}

export const memorySizeOf = (obj) => {
  var bytes = 0;

  function sizeOf(obj) {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case "number":
          bytes += 8;
          break;
        case "string":
          bytes += obj.length * 2;
          break;
        case "boolean":
          bytes += 4;
          break;
        case "object":
          var objClass = Object.prototype.toString.call(obj).slice(8, -1);
          if (objClass === "Object" || objClass === "Array") {
            for (var key in obj) {
              if (!obj.hasOwnProperty(key)) continue;
              sizeOf(obj[key]);
            }
          } else bytes += obj.toString().length * 2;
          break;
        default:
          break;
      }
    }
    return bytes;
  }

  function formatByteSize(bytes) {
    if (bytes < 1024) return bytes + " bytes";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KiB";
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MiB";
    else return (bytes / 1073741824).toFixed(3) + " GiB";
  }

  return formatByteSize(sizeOf(obj));
};

export const cloudGroupFilePaths = (groupId) => ({
  communityHtmlPath: `groups/${groupId}/content/community/html`,
  communityImages: `groups/${groupId}/images/community/coverImages`,
  postHtmlPath: `groups/${groupId}/content/post/html`,
  pagePostImagePath: `groups/${groupId}/images/post/content`,
  postImages: `groups/${groupId}/images/post/postImages`,
});
export const cloudGlobalFilePaths = {
  userProfileImagePath: `user/avatars/profile`,
  userProfileImagePathThumbail: `user/avatars/profile/thumbnail`,
};
