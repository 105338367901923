import models from "modules/models";
import BaseRxApiService from "./baseRxApiService";
import firebase from "firebase/app"
import "firebase/firestore";

export default class PackageRxApiService extends BaseRxApiService {
  constructor() {
    super(models.PACKAGE);
  }

  uncollectedPackageStream(groupId) {
    return firebase
      .firestore()
      .collection(this.documentName)
      .where("groupId", "==", groupId)
      .where("collected", "==", false);
  }

  update(data) {
    data.collected = data.collectedBy ? true : false;
    return super.update(data);
  }
}
