import React from "react";
import "typeface-roboto";
import { SnackbarProvider, withSnackbar } from "notistack";
import AppCore from "AppCore";

export default function App(props) {
  const AppWithSnackbar = withSnackbar(AppCore);
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <AppWithSnackbar {...props} />
    </SnackbarProvider>
  );
}
